import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Paper, Typography,Avatar } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { useNavigate,useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Link,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { forgotPasswordSubmit, resetPassword } from '../../utils/crud';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 64px;
  height: 64px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;


function ResetPassword() {
  const navigate = useNavigate();
  const location=useLocation()

  return (
    <Formik
      initialValues={{
        resetcode:"",
        password: "",
        confirmpassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required("New Password is required"),
        confirmpassword: Yup.string().max(255).required("Confirm Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try { 

          if(values.password.match(values.confirmpassword)){
            const jsonData={"email":location.state.userid,"resetcode":values.resetcode,"password":values.password}            
            const resp=await forgotPasswordSubmit(jsonData)
            console.log("Response=")
            console.log(resp)
                      
            if(resp=='SUCCESS'){             
              navigate("/auth/sign-in");           
            } 
          }else{
            setStatus({ success: false });
            setErrors({ submit: 'Passwords mismatch!' });
            setSubmitting(false);
          }


          //changePassword(values.email);
          //navigate("/auth/sign-in");
        } catch (error) {
          console.log("Error")
          console.log(error)
          setStatus({ success: false });
            setErrors({ submit: error });
            setSubmitting(false);

          if(JSON.parse(JSON.stringify(error)).name==='CodeMismatchException'){
            const message = " Invalid verification code provided, please try again.";           
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }else if(JSON.parse(JSON.stringify(error)).name==='LimitExceededException'){
            const message = "Attempt limit exceeded, please try after some time";           
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="resetcode"
            label="Reset Code"
            value={values.resetcode}
            error={Boolean(touched.resetcode && errors.resetcode)}
            fullWidth
            helperText={touched.resetcode && errors.resetcode}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <TextField
            type="password"
            name="password"
            label="New Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmpassword"
            label="Enter New Password Again"
            value={values.confirmpassword}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            fullWidth
            helperText={touched.confirmpassword && errors.confirmpassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Change password
          </Button>
        </form>
      )}
    </Formik>
  );
}

function ForgotPasswordSubmit() {
  const location=useLocation()
  return (
    <React.Fragment>
    
      <Wrapper>
        <Helmet title="Forgot Password Submit" />
        <Link href="/auth/sign-in"><ArrowBackIcon/></Link>
        <BigAvatar alt="Lucy" src="/static/img/brands/TCB_Blue_Bug.png" />
       
        <Typography component="h2" variant="body1" align="center">
        We have sent a password reset code by email to {location.state.destination}. Enter it below to reset your password.
        </Typography>

        <ResetPassword />
      </Wrapper>
    </React.Fragment>
  );
}

export default ForgotPasswordSubmit;
