import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Switch,
  FormControlLabel,



} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { Route, useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import { RowingSharp } from '@mui/icons-material';
import {  toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { Chip } from '@mui/material';
import dateFormat from 'dateformat';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import { Helmet } from "react-helmet-async";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
function createData(id,email,groups, last_login,isgit_owner,git_owner) {
    return { id,email,groups, last_login,isgit_owner,git_owner };
}

function DataTable() {
const [records, setRecords] = useState([]);
const [pages,setPages]=useState(1)

const addRecord = (newRecord) => {
  console.log("Add New Rocrods")
  
  setRecords([...records, newRecord]);
  console.log(records)
};
const deleteRecord = async (index) => {
  const updatedRecords = [...records];
  updatedRecords.splice(index, 1);
  setRecords(updatedRecords);
};

/********End of Global Variable SEction ******* */


const navigate = useNavigate()
const [uiRows,setUiRows]=React.useState([])

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [requestFlag,setRequestFlag]=useState(false)

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredData = uiRows.filter((row) => {
      return (
        row.title.toLowerCase().includes(keyword) ||
        row.description.toLowerCase().includes(keyword)
        
      );
    });
    setFilteredRows(filteredData);
  };

  const handleContextMenuOpen = (event, row) => {
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };
  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()
  const [email,setEmail]=React.useState()
  const [gitowner,setGitowner]=React.useState()
  const handleClickOpen = (id) => {
    setEmail(id.email)
    setGitowner(id.git_owner)    
    setOpen(true);
  };

  const handleCloses = async (confirm) => {
    setOpen(false)
    //alert(confirm)
    if(confirm=='yes'){
     
      //alert(retailerId)
      await deleteRecords(email,gitowner)     
    }else{
      setOpen(false)
      setFilteredRows([])
      await fetchMyAPI()

    }
    //alert(confirm)
    ;
  };

const deleteRecords=async(email,gitowner)=>{
 
  try{
        const mutation = `
        mutation DeleteGitOnwer {
          deleteGitOwner(input: {email: \"${email}\", git_owner: \"${gitowner}\"}) {
            ... on DeletedGitOwner {
              __typename
              email
              git_owner
            }
            ... on CustomError {
              error
              message
              code
            }
          }
        }
        `;          

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse)

if(graphqlResponse?.data?.deleteGitOwner?.error){
  toast.error(graphqlResponse.data.deleteGitOwner.message);
  setOpen(false)
 return 
}

setOpen(false)
let tmpRows=[]

// for(let i=0;i<filteredRows.length;i++){
//  if(filteredRows[i].git_owner==gitowner){
//  }else{
//    //tmpRows.push(filteredRows[i])
//  }
//  console.log(filteredRows[i].git_owner+":::::"+gitowner)
// }
//setFilteredRows(tmpRows);
//awafetchMyAPI()
toast.success('Records Deleted Successfully !');
  }catch(err){
    console.log("err")
    console.log(err)

  }
   
}

  //*******Added constants for pagination******** */

 
  //const [pagesToken,setPagesToken]=useState([])

  const pagesToken=[]
  
  
  /********Added for get data from serever */
  
  let acccessToken=null
  
  let R=0;
  
  async function fetchMyAPI() {  
    setRequestFlag(true)
    const rows=[];
    const query = `    
      query MyQuery {        
        listSuperadminUsers(limit: 50, nextToken: "") {
          data {
            email
            groups
            last_login
            sub
            username
          }
        }
        listGitOwners(debugFunctions: "getItemsPaginated", ) {
          email
          git_owner          
        }      
      }
        `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)

const GitUsers=[]

graphqlResponse?.data?.listGitOwners?.map((item)=>{

  GitUsers.push({email:item.email,git_owner:item.git_owner})

})
console.log("*************Git Users*************")
console.log(GitUsers)
/*for(let i=0;i<filteredRows.length;i++){
  if(filteredRows[i].notification_id==id){
  }else{
    tmpRows.push(filteredRows[i])
  }
 }*/

let flag=false;
let gitOwner="" 
for(let i=0;i<graphqlResponse?.data?.listSuperadminUsers?.data.length;i++){

  if(graphqlResponse?.data?.listSuperadminUsers?.data[i]?.email==GitUsers[i]?.email && GitUsers.length>0){
    flag=true    
    gitOwner=GitUsers[i].git_owner
  }else{
    flag=false
  }
  rows.push(
    createData( 
    i,
    graphqlResponse?.data?.listSuperadminUsers?.data[i].email,
    graphqlResponse?.data?.listSuperadminUsers?.data[i].groups,
    graphqlResponse?.data?.listSuperadminUsers?.data[i].last_login,
    flag,
    gitOwner
    
  ),
  )
}
console.log("***********Final Output***********")
console.log(rows)
setFilteredRows(rows)
setRequestFlag(false)
    
}
  useEffect(()=>{
    if(R>0) {R++;return;} 
    fetchMyAPI()    
    
  
  },[])
  
  
  /***************End of the fetch data */

  
  const columns = [  
    { field: 'email', headerName: 'Email',minWidth: 230,sortable: false,disableColumnMenu: true ,flex: 1,headerClassName: 'super-app-theme--header',
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
        {params.value}
       
      </div>
    ),
  
  },
    { field: 'groups', headerName: 'Role',flex: 1,minWidth: 230,sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header',  
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
        {params.value}
       
      </div>
    ),
    },
    { field: 'last_login', headerName: 'Last Login' ,minWidth:230,flexWrap: 'wrap',sortable: false,disableColumnMenu: true,includeOutliers: true,headerClassName: 'super-app-theme--header',
    includeHeaders: false,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>
     
       {params.value}
      </div>
    ),
   },
   { field: 'isgit_owner', headerName: 'API Update Permission' ,minWidth:230,flexWrap: 'wrap',sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header',
    includeHeaders: false,
    renderCell: (params) => {

      const handleChanges = (e) => {
        e.stopPropagation();
        if(e.target.checked==true){
          navigate('/update-apidocaccess',{state:{email:params.row.email}})
        }
        else{
          handleClickOpen(params.row)
        }
       
      };
  

      return (
<div style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>
       
     {<FormControlLabel
                control={
                  <Switch
                    defaultChecked ={params.value}
                    onChange={handleChanges}
                    name="ramesh"
                  />
                }
               
                /> }
      
      </div>
      )
    
      
   },
   },
    
    
   /*{ field: 'action', headerName: 'Action', minWidth: 100, sortable: false,headerClassName: 'super-app-theme--header',disableColumnMenu: true, 
   renderCell: (params) => {
     const onClick = (e) => {
       e.stopPropagation();
       console.log(params.retailer_id)      
         handleClickOpen(params.row)
     };
 
     return (
       <>
        <Button onClick={onClick} className='bi-trash'><MoreVertIcon sx={{color: 'grey'}} /></Button>
        
       </>
    
     );
   }
 
 },*/
     
    
  ];

  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
      <ListItem button>         
      <ListItemText onClick={e=>handleClickOpen(selectedRow)} primary={`Delete`} />
        </ListItem>      
       
      </List>
    </Popover>
  );

  return (
    <>
    <Helmet title="List Superadmin" />
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
    <Typography component="h1" variant="h5" align="center" gutterBottom>
    Superadmin
    </Typography> 
    </Breadcrumbs>
    <Divider my={6} />
   
    <div style={{background:'#FFF',padding:'10px',width:'100%', overflow:'hidden'}}>

       
          <DataGrid
            paddingTop='10px'
            rows={filteredRows}
            columns={columns}
            fullWidth={true}
            autoHeight 
            sx={{
            
              width: '100%',
              '& .super-app-theme--header': {
                backgroundColor: '#fff',
                fontSize:'15px',
                fontWeight:'bold'
              },
            }}
           
            components={{
              Toolbar: () => null, 
              ColumnHeaderMenu: () => null, 
              ColumnsPanel: () => null, 
              Export: () => null,
              Sortable:()=>null,
              Pagination: ()=>null,
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {requestFlag==true? <div style={{textAlign:'center'}}>
                        <img style={{width:'48px'}} src="/static/img/brands/loading.gif"/>          
                      </div>:'No Records Found'}
                  
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Local filter returns no result
                </Stack>
              )
            }}
              
          />
        {contextMenu}  

        <Paper mt={0}>
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
    </div>
    </>
  );
}

export default DataTable;
