import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Grid,
  Divider as MuiDivider,

} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { Route, useNavigate,useLocation } from "react-router-dom";
import { spacing } from "@mui/system";
import { ConstructionOutlined, RowingSharp } from '@mui/icons-material';
import {  toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip } from '@mui/material';
import { NavLink } from "react-router-dom";
import {isMobile } from 'react-device-detect';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import { Helmet } from "react-helmet-async";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
function createData(id,email,tenant, updated_at) {
    return { id,email,tenant,  updated_at };
}


function DataTable() {
const navigate = useNavigate()
const location=useLocation()
const [uiRows,setUiRows]=React.useState([])

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredData = uiRows.filter((row) => {
      return (
        row.title.toLowerCase().includes(keyword) ||
        row.description.toLowerCase().includes(keyword)
        
      );
    });
    setFilteredRows(filteredData);
  };

  const handleContextMenuOpen = (event, row) => {   
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentToken,setCurrentToken]=useState('')
  const [nextToken,setNextToken]=useState('')
  const [previousToken,setPreviousToken]=useState('')
  const [requestFlag,setRequestFlag]=useState(false)
  
  
  /********Added for get data from serever */
  
  let acccessToken=null
  
  let R=0;
  
  async function fetchMyAPI() {
    setRequestFlag(true)
    const rows=[];
    const query = `
    query ListEnterpriseUsers {
      listEnterpriseUsers(limit: 50, tenant: \"${location.state.tenant.tenant}\", nextToken: "") {
        data {
          created_at
          created_by
          email
          tenant
          updated_at
          updated_by
        }
        nextToken
      }
    }
        `;  


  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse?.data?.listEnterpriseUsers?.data?.map((item)=>{
    rows.push(
      createData( 
      i,
      item.email,
      item.tenant,      
      item.updated_at,
    ),
    )
   i++;
    
    })
    setCurrentToken('')  
    setPreviousToken('')
    setNextToken(graphqlResponse?.data?.listEnterpriseUsers?.nextToken)      
    console.log("Current Tone::"+graphqlResponse?.data?.listEnterpriseUsers?.nextToken)
    setUiRows(rows)
    setFilteredRows(rows)
    setRequestFlag(false)
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse?.data?.listRequestEnterprises)
}


async function fetchDataWithToken(token) {
  setPreviousToken(currentToken) 
  const rows=[]
  const query = `
  query ListNotifications {
      listNotifications(limit: 3, nextToken: \"${token}\") {
        data {
          created_at
          created_by
          description
          link
          notification_id
          title
          updated_at
          updated_by
        }
        nextToken
      }
    }
      `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse?.data?.listNotifications?.data.map((item)=>{
  rows.push(
    createData( 
    i,
    item.notification_id,
    item.title,
    item.link,   
    item.description,
    item.created_at,
    item.created_by 
  
  ),
  )
 i++;
  
  })
  
  
  setCurrentToken(token)  
  setNextToken(graphqlResponse.data.listNotifications.nextToken)     
  console.log("Current Tone::"+graphqlResponse.data.listNotifications.nextToken)
  setUiRows(rows)
  setFilteredRows(rows)
  
  console.log("Data rows" + JSON.stringify(rows))
  console.log("Graph ql rESPONSE=")
  console.log(graphqlResponse.data.listNotifications)
}

  useEffect(()=>{
    if(location.state==null)
    navigate("/all-account")
   
    if(R>0) {R++;return;}       
    fetchMyAPI()
  
  },[])
  
  
  /***************End of the fetch data */

  const columns = [
   
    { field: 'email', headerName: 'User Email',sortable: false, minWidth: 200, flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',disableColumnMenu: true,},   
      
    { field: 'updated_at', headerName: 'Created Date',sortable: false,minWidth: 150 ,flex: 1,headerClassName: 'super-app-theme--header',disableColumnMenu: true,
    renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
        
          {new Date(params.value).toUTCString()}

        </div>
      ),
    },  
    { field: 'action', headerName: 'Action', minWidth: 100,sortable: false,headerClassName: 'super-app-theme--header',disableColumnMenu: true, 
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
     
        console.log(params.row.enterprise_id)
        //handleContextMenuOpen(e, params.row);
        handleClickOpen(params.row)
      };
  
      return (
        <>
         <Button onClick={onClick} className='bi-trash'><DeleteIcon sx={{color: 'grey'}} /></Button>
         
        </>
     
      );
    }
  
  },
  ];
  
  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()

  
  const handleClickOpen = (id) => {
      setDelItem(id)
      setOpen(true);
   
   
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){
     
      await deleteRecords(delItem)
     
    }else{
      setOpen(false)
    }
    //alert(confirm)
    ;
  };

const deleteRecords=async(id)=>{
 
  try{
    const mutation = `
    mutation DeleteEnterpriseUser {
      deleteEnterpriseUser(email: \"${id.email}\", tenant: \"${id.tenant}\") {
        ... on DeletedEnterpriseUser {
          __typename
          email
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }  
        `;  

console.log(mutation)        

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse)
//console.log(graphqlResponse?.data?.deleteEnterpriseUser?.code)

if(graphqlResponse?.data?.deleteEnterpriseUser?.code){
  toast.error(graphqlResponse.data.deleteEnterpriseUser?.code);
  setOpen(false)
 return 
}

setOpen(false)
let tmpRows=[]
for(let i=0;i<filteredRows.length;i++){
 if(filteredRows[i].email==id.email){
 }else{
   tmpRows.push(filteredRows[i])
 }
}
setFilteredRows(tmpRows);
toast.success('Records Deleted Successfully !');


  }catch(err){

    console.log("err")
    console.log(err)

  }
   
}
const [page, setPage] = useState(1);
const [rowsPerPage, setRowsPerPage] = useState(2);

// Your data source (replace with your own data source)
const allData = [
  // ... your data
];

// Calculate the data for the current page
const startIndex = (page - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const data = filteredRows.slice(startIndex, endIndex);
  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>      
        <ListItem button>
          <ListItemText onClick={e=>handleClickOpen(selectedRow,'Create')} primary={`Create Enterprise`} />
        </ListItem>
        <ListItem button>
          <ListItemText onClick={e=>handleClickOpen(selectedRow,'Delete')} primary={`Delete`} />
        </ListItem>

      </List>
    </Popover>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  return (
    <div style={{ width: '100%' }}>

<Helmet title="List Enterprise Users" />
     

      <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={6}>
        <Link component={NavLink} to="/all-account">
          All Acounts
        </Link>       
        <Typography>List Enterprise Users</Typography>
      </Breadcrumbs>
      <Divider my={6} />
       
      <Card mb={6}>
        <CardContent>
        <Grid container spacing={12}>
        <Grid item >
        
      
        </Grid>
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >
       
        <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
        <Button mr={2} variant="contained" color="primary" style={{padding:isMobile?'5px':''}} onClick={e=>navigate('/create-enterpriseuser',{state:{tenant:location.state.tenant}})}>
          + Add
        </Button>
        </div>
        </Grid>

          
        </Grid>  
         
      <DataGrid
        paddingTop='10px'
        rows={filteredRows}
        columns={columns}
        fullWidth={true}
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#fff',
            fontSize:'15px',
            fontWeight:'bold'
          },
        }} 
        autoHeight  
        pageSizeOptions={[5,10,15]}
        components={{
          Toolbar: () => null, 
          ColumnHeaderMenu: () => null, 
          ColumnsPanel: () => null, 
          Export: () => null,
          Sortable:()=>null,
          Pagination: ()=>  null ,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {requestFlag==true? <div style={{textAlign:'center'}}>
                    <img style={{width:'48px'}} src="/static/img/brands/loading.gif"/>          
                  </div>:'No Records Found'}
              
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>
          ) 
        }}
        onCellClick={(params, event) => {
          
        }}
      />
      {contextMenu}     
          
          <Paper mt={2}>
          
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </CardContent>
      </Card>  


     
    </div>
  );
}

export default DataTable;
