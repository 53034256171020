import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Divider as MuiDivider,


} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { NavLink, useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import { RowingSharp } from '@mui/icons-material';
import {  toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
function createData(id,enterprise_id,enterprise_name, website_url,address_info,description , updated_at) {
    return { id,enterprise_id,enterprise_name, website_url,address_info,description , updated_at };
}


function DataTable() {
const navigate = useNavigate()
const [uiRows,setUiRows]=React.useState([])

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredData = uiRows.filter((row) => {
      return (
        row.title.toLowerCase().includes(keyword) ||
        row.description.toLowerCase().includes(keyword)
        
      );
    });
    setFilteredRows(filteredData);
  };

  const handleContextMenuOpen = (event, row) => {
   
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentToken,setCurrentToken]=useState('')
  const [nextToken,setNextToken]=useState('')
  const [previousToken,setPreviousToken]=useState('')
  
  
  
  /********Added for get data from serever */
  
  let acccessToken=null
  
  let R=0;
  
  async function fetchMyAPI() {
    const rows=[];
    const query = `
    query ListRequestEnterprises {
        listRequestEnterprises(limit: 10, nextToken: "") {
          data {
            enterprise_id
            enterprise_name
            updated_at
            website_url
            address_info
            description
          }
          nextToken
        }
      }
        `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse.data.listRequestEnterprises.data.map((item)=>{
    rows.push(
      createData( 
      i,
      item.enterprise_id,
      item.enterprise_name,
      item.website_url,
      item.address_info,
      item.description,   
      item.updated_at,
    
    ),
    )
   i++;
    
    })
    setCurrentToken('')  
    setPreviousToken('')
    setNextToken(graphqlResponse.data.listRequestEnterprises.nextToken)      
    console.log("Current Tone::"+graphqlResponse.data.listRequestEnterprises.nextToken)
    setUiRows(rows)
    setFilteredRows(rows)
    
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse.data.listRequestEnterprises)
}


async function fetchDataWithToken(token) {
  setPreviousToken(currentToken) 
  const rows=[]
  const query = `
  query ListNotifications {
      listNotifications(limit: 3, nextToken: \"${token}\") {
        data {
          created_at
          created_by
          description
          link
          notification_id
          title
          updated_at
          updated_by
        }
        nextToken
      }
    }
      `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse.data.listNotifications.data.map((item)=>{
  rows.push(
    createData( 
    i,
    item.notification_id,
    item.title,
    item.link,   
    item.description,
    item.created_at,
    item.created_by 
  
  ),
  )
 i++;
  
  })
  
  
  setCurrentToken(token)  
  setNextToken(graphqlResponse.data.listNotifications.nextToken)     
  console.log("Current Tone::"+graphqlResponse.data.listNotifications.nextToken)
  setUiRows(rows)
  setFilteredRows(rows)
  
  console.log("Data rows" + JSON.stringify(rows))
  console.log("Graph ql rESPONSE=")
  console.log(graphqlResponse.data.listNotifications)
}

  useEffect(()=>{
    if(R>0) {R++;return;}       
    fetchMyAPI()
  
  },[])
  
  
  /***************End of the fetch data */

  const columns = [
   
    { field: 'enterprise_name', headerName: 'Enterprise Name',sortable: false,disableColumnMenu: true, minWidth: 100, flex: 1,flexWrap: 'wrap'},   
    { field: 'website_url', headerName: 'Website Url',sortable: false,disableColumnMenu: true,flex: 1,minWidth: 100,  
    renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
          <a href={params.value} target='_blank'>{params.value}</a>
        </div>
      ),
    },
    { field: 'address_info', headerName: 'Address',sortable: false,disableColumnMenu: true,flex: 1,minWidth: 150,  
    renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
          {params.value}
        </div>
      ),
    },
    { field: 'description', headerName: 'Description',sortable: false,disableColumnMenu: true,flex: 1,minWidth: 150,  
    renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
          {params.value}
        </div>
      ),
    },
    { field: 'updated_at', headerName: 'Request Date',sortable: false,disableColumnMenu: true,minWidth: 150 ,flex: 1,
    renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
        
          {new Date(params.value).toUTCString()}

        </div>
      ),
    },  
    { field: 'action', headerName: 'Action',sortable: false,disableColumnMenu: true, minWidth: 100, flex: 1, 
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
     
        console.log(params.row.enterprise_id)
        handleContextMenuOpen(e, params.row);
      };
  
      return (
        <>
        <Button onClick={onClick}><MoreVertIcon/></Button>
         
        </>
     
      );
    }
  
  },
  ];
  
  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()

  
  const handleClickOpen = (id,otype) => {
    setDelItem(id.enterprise_id)
    if(otype=='Create'){
      navigate('/create-account',{state:{data:id}})
    }else{
      setOpen(true);
    }
   
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){
     
      await deleteRecords(delItem)
     
    }else{
      setOpen(false)
    }
    //alert(confirm)
    ;
  };

const deleteRecords=async(id)=>{
 
  try{
    const mutation = `
    mutation DeleteRequestEnterprise {
      deleteRequestEnterprise(enterprise_id: \"${id}\") {
        ... on DeletedRequestEnterprise {
          enterprise_id
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }  
        `;  

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse?.data?.deleteRequestEnterprise.code)

if(graphqlResponse?.data?.deleteRequestEnterprise?.code){
  toast.error(graphqlResponse.data.deleteRequestEnterprise.code);
  setOpen(false)
 return 
}

setOpen(false)
let tmpRows=[]
for(let i=0;i<filteredRows.length;i++){
 if(filteredRows[i].enterprise_id==id){
 }else{
   tmpRows.push(filteredRows[i])
 }
}
setFilteredRows(tmpRows);
toast.success('Records Deleted Successfully !');


  }catch(err){

    console.log("err")
    console.log(err)

  }
   
}
const [page, setPage] = useState(1);
const [rowsPerPage, setRowsPerPage] = useState(2);

// Your data source (replace with your own data source)
const allData = [
  // ... your data
];

// Calculate the data for the current page
const startIndex = (page - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const data = filteredRows.slice(startIndex, endIndex);
  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>      
        <ListItem button>
          <ListItemText onClick={e=>handleClickOpen(selectedRow,'Create')} primary={`Create Enterprise`} />
        </ListItem>
        <ListItem button>
          <ListItemText onClick={e=>handleClickOpen(selectedRow,'Delete')} primary={`Delete`} />
        </ListItem>

      </List>
    </Popover>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  return (
    <div style={{ width: '100%' }}>

      <Helmet title="View Enterprise Details" />
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>  
          <Link component={NavLink} to="/all-account">
            All Account
          </Link>
          <Typography>View Enterprise Requests</Typography>
        </Breadcrumbs>
        <Divider my={6} />
              
      <Card mb={6}>
        <CardContent>
          
        
      <DataGrid
        paddingTop='10px'
        rows={filteredRows}
        columns={columns}
        fullWidth={true}
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#fff',
            fontSize:'15px',
            fontWeight:'bold'
          },
        }} 
        autoHeight  
        pageSizeOptions={[5,10,15]}
        components={{
          Toolbar: () => null, 
          ColumnHeaderMenu: () => null, 
          ColumnsPanel: () => null, 
          Export: () => null,
          Sortable:()=>null,
          Pagination: ()=>null         
        }}
        onCellClick={(params, event) => {
          
        }}
      />
      {contextMenu}     
         
          <Paper mt={2}>
          
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </CardContent>
      </Card>  


     
    </div>
  );
}

export default DataTable;
