import React,{useEffect, useState} from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink,useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
} from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl as MuiFormControl,
} from "@mui/material";
import {
  Done as DoneIcon,
  Face as FaceIcon,
  TagFaces as TagFacesIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

import { useLocation } from "react-router-dom";
import { getListItems,deleteItem,createItem } from '../../utils/crud'
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import {  toast } from 'react-toastify';

const FormControl = styled(MuiFormControl)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;



function Roles() {
const location=useLocation()
const [serverResponse,setServerResponse]=useState([])
useEffect(()=>{
  if(location?.state==null){
navigate('/all-account')
  }
getRoles()
},[])
const navigate = useNavigate()
function createData(value,label) {
  return { value,label };
}
  const [personName, setPersonName] = React.useState([]);
  const [listRoles,setListRoles]=React.useState([])
  function createData(value,label) {
    return { value,label };
}

  let R=0; 
  useEffect(()=>{
  console.log(location.state)
    if(R>0) {R++;return;} 
    if(location?.state==null){
      navigate('/all-account')
        }      
    fetchMyAPI()
  },[])


  async function fetchMyAPI() {
    await getRoles()
    console.log("2. Users Roles=")
   
    const rows=[]
    const query = `
    query MyQuery {
      listRoles
    }
  `; 

  const graphqlResponse =  await getListItems(query) 
  const rowsInfo=graphqlResponse.data.listRoles;
  rowsInfo.map((item)=>{
    rows.push(createData(
      item,
      item

    ))

    })
  setListRoles(rows)
  console.log("Rows Info=")
  console.log(rows) 
  }

  const [roleofTenant,setRoleofTenant]=React.useState([])
  const getRoles=async()=>{
    const rows=[]
    const query = `
    query ListEnterpriseRoles {
      listEnterpriseRoles(tenant: \"${location.state.tenant.tenant}\") {
        created_at
        created_by
        deleted_at
        role_name
        tenant
        updated_at
        updated_by
      }
    }
        `;  

  const graphqlResponse =  await getListItems(query)
  console.log(graphqlResponse)
  graphqlResponse.data.listEnterpriseRoles.map((item)=>{
  rows.push(item.role_name)
  })
  setRoleofTenant(rows)
  console.log("1. Users Roles=")
  console.log(rows)
  }

  const [isDisable,setIsDisable]=useState(false)
  const deleteEnterpriseRole=async(role)=>{
    setIsDisable(true)  
    const mutation=`mutation DeleteEnterpriseRole {
      deleteEnterpriseRole(role_name: \"${role}\", tenant: \"${location.state.tenant.tenant}\") {
        ... on DeletedEnterpriseRole {
          __typename
          role_name
          tenant
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }`

    const graphqlResponse =  await deleteItem(mutation)
    console.log(graphqlResponse)
    const error=graphqlResponse?.data?.deleteEnterpriseRole?.error
    if(error){
      toast.error(graphqlResponse?.data?.deleteEnterpriseRole?.message,{
        autoClose: 1000,
      })
    }else{
      toast.error("Role Deleted Successfully !",{
      autoClose: 1000,
    })
    }
  }

  const createEnterpriseRole=async(role)=>{
    setIsDisable(true)  
    const mutation=`mutation CreateEnterpriseRole {
      createEnterpriseRole(role_name: \"${role}\", tenant: \"${location.state.tenant.tenant}\") {
        ... on EnterpriseRole {
          deleted_at
          created_at
          created_by
          role_name
          tenant
          updated_at
          updated_by
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }`

    const graphqlResponse =  await createItem(mutation)
    console.log(graphqlResponse)
    const error=graphqlResponse?.data?.createEnterpriseRole?.error
    if(error){
      toast.error(graphqlResponse?.data?.createEnterpriseRole?.message)
    }else{
      toast.success("Role Updated Successfully !",{
        autoClose: 1000,
      })
    }
  }

 

  const handleChanges = async (event) => {
    //alert(event.target.name)
    if(event.target.checked==true){
    
      await createEnterpriseRole(event.target.name)
      setIsDisable(false)   
    }else{

      await deleteEnterpriseRole(event.target.name)
      setIsDisable(false)  
    }
   
    //setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (

    <Card mb={6}>
      <CardContent>
       

        <Grid container spacing={12}>
            <Grid item md={6}>
            <Typography variant="h6" gutterBottom>
          Roles
        </Typography>
            
            </Grid>
        <Grid item style={{flex:1, textAlign:'end',paddingBottom:'6px'}}>
            <Typography variant="body2" gutterBottom>
              Roles Assigned to the enterprise
              </Typography> 
        </Grid>
      
  <Grid container spacing={6} mx={20}>
            <Grid item md={6}>           

            <FormControl component="fieldset" variant="standard">
           
            {listRoles.length>0 &&
             <FormGroup>
             {listRoles.map((item,index)=>{
              return(             
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked ={roleofTenant.indexOf(item.label)>=0?true:false}                   
                    onChange={handleChanges}
                    disabled={isDisable}
                    name={item.label}
                  />
                }
                label={item.label}
                /> 
                ) 
             })
              
             } 
                      
           </FormGroup>
            }
           
          </FormControl>
            </Grid>
            
                      
          </Grid>
        </Grid> 

        
       
      </CardContent>
    </Card>
    
  );
}


function EnterpriseUsers() {
  const handleDelete = (data) => () => {
    if (data.label === "React") {
      alert("Why would you want to delete React?! :)"); // eslint-disable-line no-alert
      return;
    }

    setChipData(chipData.filter((item) => item.key !== data.key));
  };
  const [chipData, setChipData] = useState([
    { key: 0, label: "admin@example.com" },
    { key: 1, label: "rahul@rezior.com" },
    { key: 2, label: "rakesh@rezior.com" },
    { key: 3, label: "tech@rezior.com" },
    { key: 4, label: "somkiran@rezior.com" },
    { key: 5, label: "owner1@example.com" },
   
  ]);


const location=useLocation()
const [filteredRows,setFilteredRows]=useState([])
const [requestFlag,setRequestFlag]=useState(false)
const navigate = useNavigate()

function createData(id,email,tenant, updated_at) {
  return { id,email,tenant,  updated_at };
}

const getUsers=async()=>{
  setRequestFlag(true)
  const rows=[]
  const query=`
  query ListEnterpriseUsers {
    listEnterpriseUsers(limit: 10, tenant: \"${location.state.tenant.tenant}\", nextToken: "") {
      data {
        created_at
        created_by
        email
        tenant
        updated_at
        updated_by
      }
      nextToken
    }
  }  
  `
  const graphqlResponse =  await getListItems(query)
  console.log("Enterprise user response=")
  console.log(graphqlResponse)
  let i=1
  graphqlResponse.data.listEnterpriseUsers.data.map((item)=>{
      rows.push(
        createData( 
        i,
        item.email,
        item.tenant,      
        item.updated_at,
      ),
      )
     i++;
      
      })

  const response=await getListItems(query)
  setFilteredRows(rows)
  setRequestFlag(false)
}

useEffect(()=>{
  if(location?.state==null){
    navigate('/all-account')
      }
  getUsers()
},[])


const columns = [
   
  { field: 'email', headerName: 'User Email', minWidth: 200, flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',disableColumnMenu: true,},   
   
  { field: 'updated_at', headerName: 'Created Date',minWidth: 150 ,flex: 1,headerClassName: 'super-app-theme--header',disableColumnMenu: true,
  renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
      
        {new Date(params.value).toUTCString()}

      </div>
    ),
  },  
  { field: 'action', headerName: 'Action', minWidth: 100,sortable: false,headerClassName: 'super-app-theme--header',disableColumnMenu: true, 
  renderCell: (params) => {
    const onClick = (e) => {
      e.stopPropagation();
   
      console.log(params.row.enterprise_id)
      //handleContextMenuOpen(e, params.row);
      handleClickOpen(params.row)
    };

    return (
      <>
       <Button onClick={onClick} className='bi-trash'><DeleteIcon sx={{color: 'grey'}} /></Button>
       
      </>
   
    );
  }

},
];

  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()
  const handleClickOpen = (id) => {
    setDelItem(id)
    setOpen(true);
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){
      //alert(deleteItem)
      //await deleteNotification(delItem)
     
    }else{
      setOpen(false)
    }
    //alert(confirm)
    ;
  };

  //miui pagination handling
const [records, setRecords] = useState([]);
const [pages,setPages]=useState(1)

const addRecord = (newRecord) => {
  console.log("Add New Rocrods")
  
  setRecords([...records, newRecord]);
  console.log(records)
};
const deleteRecord = async (index) => {
  const updatedRecords = [...records];
  updatedRecords.splice(index, 1);
  setRecords(updatedRecords);
};

/********End of Global Variable SEction ******* */

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2; 
  const data1 = filteredRows; 
  const pageCount = Math.ceil(data1.length / itemsPerPage);
  const startPage = (currentPage - 1) * itemsPerPage;
  const endPage = startPage + itemsPerPage;
  const currentPageData = data1.slice(startPage, endPage);


  const handlePageChange =async(event, page) => {
    //alert(page + ":" + records.length)   
    if(records.length>=1){     
      if(page===1){
        for(let i=0;i<records.length;i++){
          deleteRecord(records.length-i) 
        }
        //alert(records.length)        
        getUsers()        
      }else{
        //alert(records.length) 
        //fetchDataWithToken(records[page-2].token,page)  
      }      
      setCurrentPage(page);
    }
  };

  return (

    <Card mb={6}>
      <CardContent>
       
        <Grid container spacing={12}>
            <Grid item >
            <Typography variant="h6" gutterBottom>
          Enterprise User Details
        </Typography>      
            </Grid>
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >
          <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
          <Button mr={2} variant="contained" color="primary"  onClick={e=>navigate('/create-enterpriseuser',{state:{tenant:location.state.tenant,breadcrumbs:{text:'View Enterprise Details',url:'/view-enterprisedetails'}}})}>
          + Add
        </Button>
        </div>
         
        </Grid>
        </Grid>        
        <div>
        <DataGrid
        paddingTop='10px'
        rows={filteredRows}
        columns={columns}
        fullWidth={true}
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#fff',
          },
        }} 
        autoHeight  
        pageSizeOptions={[5,10,15]}
        components={{
          Toolbar: () => null, 
          ColumnHeaderMenu: () => null, 
          ColumnsPanel: () => null, 
          Export: () => null,
          Sortable:()=>null,
          Pagination: ()=>  {

            return (
              <div>            
              {/* Pagination component */}
              <Stack direction="row" justifyContent="center">
                <Pagination
                  count={records.length+1}
                  page={currentPage}
                  onChange={handlePageChange}
                  boundaryCount={10} // Adjust as needed
                  showFirstButton
                  showLastButton
                  renderItem={(item) => (
                    <PaginationItem
                      component="div"
                      {...item}
                    />
                  )}
                />
              </Stack>
            </div>  
            )
         },
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {requestFlag==true? <div style={{textAlign:'center'}}>
                    <img style={{width:'48px'}} src="/static/img/brands/loading.gif"/>          
                  </div>:'No Records Found'}
              
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>
          )           
        }}
        onCellClick={(params, event) => {
          
        }}
      />
          {/*serverResponse.map((data) => {
            let icon = null;
            return icon ? (
              <Chip
                key={data.key}               
                label={data.email}
                color="success"               
                m={1}
              />
            ) : (
              <Chip
               color="success"
                key={data.key}
                label={data.email}
               
                m={1}
              />
            );
          })*/}
        </div>
      </CardContent>
    </Card>
    
  );
}

function EnterpriseDetails() {

const location=useLocation()
const [serverResponse,setServerResponse]=useState([])

const getEnerprise=async()=>{
  const query=`
  query MyQuery {
    getEnterprise(tenant: \"${location.state.tenant.tenant}\") {
      ... on Enterprise {
        deleted_at
        deactivated
        created_at
        created_by
        enterprise_name
        description
        tenant
        website_url
        address_info
        updated_at
        updated_by
      }
      ... on CustomError {
        error
        message
        code
      }
    }
  }
  `
  const response=await getListItems(query)
  console.log("response=")
  console.log(response?.data?.getEnterprise)
  setServerResponse(response?.data?.getEnterprise)
}

useEffect(()=>{
getEnerprise()
},[])
const navigate = useNavigate()
  return (
    <>
     {serverResponse ?<>

      <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Enterprise Details
        </Typography>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Website</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {serverResponse.enterprise_name}
                </TableCell>
                <TableCell>
                 {serverResponse.description}
                  
                </TableCell>
                <TableCell>{serverResponse.address_info}</TableCell>
                <TableCell><Link href={serverResponse.website_url} target="_blank" ><LinkIcon/></Link></TableCell>
                <TableCell><Button onClick={e=>navigate('/update-account',{state:{data:serverResponse}})}  ><EditIcon/></Button></TableCell>
              </TableRow>              
            </TableBody>
          </Table>

          
        </TableWrapper>
      </CardContent>
    </Card>

     
     </>:<>
     
     </>
      
      }
    </>
   
    
  );
}

function GetConnectedDetails() {

const location=useLocation()
const [serverResponse,setServerResponse]=useState([])

const getEnerprise=async()=>{
  const query=`
  query ListEnterpriseConnections {
    listEnterpriseConnections(tenant: \"${location.state.tenant.tenant}\") {
      deleted_at
      connected_tenant
      connected_type
      created_at
      created_by
      tenant
      type
      updated_at
      updated_by
    }
  }
  `
  const response=await getListItems(query)
  console.log("Enterprise Connection Response=")
  //console.log(response?.data?.listEnterpriseConnections)
  setServerResponse(response?.data?.listEnterpriseConnections)

  
}

useEffect(()=>{
getEnerprise()
},[])

const navigate = useNavigate()

const handleEnterprise=()=>{

  navigate('/create-enterpriseconnection',{state:{data:location.state.tenant,breadcrumbs:{text:'View Enterprise Details',url:'/view-enterprisedetails'}}})
 
}
  
  return (
    <>
   {serverResponse && serverResponse.length>0 ?<>
   
    <Card mb={6} >
      <CardContent>
        
        <Grid container spacing={12}>
            <Grid item >
            <Typography component="h1" variant="h5" align="center" gutterBottom>
            Enterprise Connection Details
            </Typography>      
            </Grid>
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >
          <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
            <Button mr={2} variant="contained" color="primary" onClick={handleEnterprise}>
            +Add
            </Button>
          </div>
        </Grid>
        </Grid> 
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                {/*<TableCell>Enterprise From</TableCell>*/}
                <TableCell>Role</TableCell>
                <TableCell>To Enterprise</TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>         
          
            {serverResponse.map((data) => {
             return (             
              <TableRow>
              
                {/*<TableCell component="th" scope="row">
                {data.connected_tenant}
             </TableCell>*/}
                <TableCell>
                {data.connected_type}
                </TableCell>
                <TableCell>{data.tenant}</TableCell>
                <TableCell>{data.type}</TableCell>
              </TableRow>
             )             
              }
            )}                            
            </TableBody>
          </Table>
        </TableWrapper>
      </CardContent>
    </Card>
    
    </>:<>
     
     </>
      
      }
    </>
    
  );
}


function Profile() {

  return (
    <div style={{width:'100%'}}>
      <Helmet title="View Enterprise Details" />
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>        
        <Link component={NavLink} to="/all-account">
          All Account
        </Link>
        <Typography>View Enterprise Details</Typography>
      </Breadcrumbs>
        <Divider my={6} />
        <EnterpriseDetails/>
        <Roles />
        <GetConnectedDetails/>
        <EnterpriseUsers/>

      <Divider my={6} />

      </div>
  );
}

export default Profile;
