import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from './aws-exports';
import OtpInput from 'react-otp-input';
const Alert = styled(MuiAlert)(spacing);

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
  },
  
});

const TextField = styled(MuiTextField)(spacing);
function VerifyOtp() {
  const navigate = useNavigate();
  const { verifyOtp } = useAuth();
  const [otp, setOtp] = useState('');
  return (
    <Formik
      initialValues={{
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required("Verification Code is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {


        const user=await Auth.currentAuthenticatedUser()
        console.log("Current User=")
        console.log(user)
          //resetPassword(values.email);
          //alert(values.password)
          navigate("/private");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="password"
            label="Verification Code"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

        {/*<OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
          />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Inform Admin
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default VerifyOtp;
