import React,{useEffect} from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { isMobile } from "react-device-detect";

const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function EmptyCard() {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  const navigate = useNavigate();
  const location=useLocation();
  const [personName, setPersonName] = React.useState([]);


  const [tenant,setTenant]=React.useState("");
  useEffect(()=>{
    if(location?.state==null){
      navigate('/all-account')
        }
    console.log('Retailer ID')
    console.log(location.state.retailer_id)
   
  },[])
  


  return (
    <Formik 
      initialValues={{
        latitude:'',
        longitude: '',
        retailer_name: location.state.retailer_name,
        store_description: '',
        store_id: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        latitude: Yup.number().max(180).required("Latitude is required"),
        longitude: Yup.number().max(180).required("Longitude is required"),
     
        store_description:Yup.string().max(255).required("Store Description is required"),
        store_id:Yup.string().max(255).required("Store ID is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        
        try {
          
          let cSession=await Auth.currentSession()
          let acccessToken= cSession.getAccessToken()
          let awtToken= acccessToken.getJwtToken()

          Amplify.configure({
            API:{
            graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
            graphql_headers : async () =>({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${awtToken}`, 
        
            })        
          }})       
          const tenant=values.identity + ".thecouponbureau.org";

          const mutation=`mutation CreateLiveStore {
            createLiveStore(liveStore:  {latitude:${values.latitude.toString()}, longitude:${values.longitude.toString()}, retailer_id: \"${location.state.retailer_id.toString()}\", store_description: \"${values.store_description.toString()}\", store_id: \"${values.store_id.toString()}\"}) {
              ... on LiveStore {
                store_description
                created_at
                created_by
                latitude
                longitude
                retailer_id
                store_id
                updated_at
                updated_by
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }`
          console.log(mutation)


          
          const graphqlResponse = await API.graphql({
            query: mutation,
            authMode: 'AMAZON_COGNITO_USER_POOLS', // Use this if you're using Cognito
            variables: { limit: 10 }, // Your query variables
            headers: {
              Authorization: awtToken,
            },
          });
          
          console.log("Graph ql rESPONSE=")
          console.log(graphqlResponse)

          if(graphqlResponse.data.createLiveStore.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse.data.createLiveStore.message});
          }else(
            navigate("/live-stores",{state:{retailer_id:location?.state?.retailer_id,retailer_name:location?.state?.retailer_name}})
          )
        
         
        } catch (error) {
          console.log(error)
          

    

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
          {/*<Alert mt={3} mb={3} severity="success">
            {localStorage.getItem("email")}
      </Alert>*/}

<Card mb={6}>
<CardContent>
<Typography variant="h6" gutterBottom>
            Store info
          </Typography>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}>
            <TextField
              type="number"
              name="latitude"
              label="Latitude"
              value={values.latitude}
              error={Boolean(touched.latitude && errors.latitude)}
              fullWidth
              helperText={touched.latitude && errors.latitude}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            <Grid item md={6}> 

            <TextField
              type="number"
              name="longitude"
              label="Longitude"
              value={values.longitude}
              error={Boolean(touched.longitude && errors.longitude)}
              fullWidth
              helperText={touched.longitude && errors.longitude}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            </Grid>

            <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}>
            <TextField
              type="text"
              name="retailer_id"
              label="Retailer"
              disabled
              value={values.retailer_name}
              error={Boolean(touched.retailer_name && errors.retailer_name)}
              fullWidth
              helperText={touched.retailer_name && errors.retailer_name}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            <Grid item md={6}>
            <TextField
              type="text"
              name="store_id"
              label="Store ID"
              value={values.store_id}
              error={Boolean(touched.store_id && errors.store_id)}
              fullWidth
              helperText={touched.store_id && errors.store_id}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            </Grid>
          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}> 
            <TextField
              type="text"
              name="store_description"
              label="Description"
              value={values.store_description}
              error={Boolean(touched.store_description && errors.store_description)}
              fullWidth
              helperText={touched.store_description && errors.store_description}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
          
          
            </Grid>
          
          </Grid>

        
          <Button type="submit" disabled={isSubmitting} fullWidth={isMobile?true:false} variant="contained" color="primary">
            Create Live Store
          </Button>
         
  </CardContent>  
  </Card>        
          
        </form>
      )}
    </Formik>
  );
}


function CreateStore() {
  const navigate=useNavigate()
  const location=useLocation()
  return (
    <React.Fragment>
      <Helmet title="Create Live Store" />
      <Typography variant="h3" gutterBottom display="inline">
         Live Store
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>

      <Link to="#">
         <Button onClick={(e)=>{
        
            navigate("/live-stores",{state:{retailer_id:location?.state?.retailer_id,retailer_name:location?.state?.retailer_name}})
          }}
         >
        List Live Stores        
         </Button>
        </Link>        
        
        <Typography>Create Live Store</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateStore;
