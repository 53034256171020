import React,{useEffect} from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { isMobile } from "react-device-detect";
import { createItem,deleteItem } from '../../utils/crud';

const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function EmptyCard() {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  const navigate = useNavigate();
  const location=useLocation();
  const [personName, setPersonName] = React.useState([]);


  const [tenant,setTenant]=React.useState("");
  useEffect(()=>{
    if(location?.state==null){
      navigate('/all-account')
        }
    console.log('Retailer ID')
    console.log(location.state.retailer_id)
   
  },[])
  


  return (
    <Formik 
      initialValues={{
        email:location.state.email,
        git_owner: '',
        git_repo:'' ,
        git_branch: '',
        git_token: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        git_owner: Yup.string().max(180).required("Git Owner is required"),
        git_repo: Yup.string().max(180).required("Git Repo is required"),
     
        git_branch:Yup.string().max(255).required("Git Branch is required"),
        git_token:Yup.string().max(255).required("Git Token is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        
        try {
     

          const mutation=`mutation AddGitOnwer {
            addGitOwner(input: {email: \"${location.state.email}\", git_owner: \"${values.git_owner}\", git_repo:\"${values.git_repo}\", git_branch: \"${values.git_branch}\", git_token: \"${values.git_token}\"}) {
              ... on GitOwner {
                __typename
                email
                git_owner
                git_repo
                git_branch
                git_token
                git_token_encrypted
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }`
          console.log(mutation)
          const graphqlResponse=await createItem(mutation)       
          console.log("Graph ql rESPONSE=")
          console.log(graphqlResponse)
          if(graphqlResponse?.data?.addGitOwner?.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse?.data?.addGitOwner?.message});
          }else{
           navigate("/list-superadmin")
          }
           
          
        
         
        } catch (error) {
          console.log(error)
          

    

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
          {/*<Alert mt={3} mb={3} severity="success">
            {localStorage.getItem("email")}
      </Alert>*/}

<Card mb={6}>
<CardContent>
<Typography variant="h6" gutterBottom>
          Update Api Doc Access Info for {location.state.email}
          </Typography>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}>
            <TextField
              type="text"
              name="git_owner"             
              label="Git Owner"
              
              value={values.git_owner }
              error={Boolean(touched.git_owner && errors.git_owner)}
              fullWidth
              helperText={touched.git_owner && errors.git_owner}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            <Grid item md={6}> 

            <TextField
              type="text"
              name="git_repo"
              label="Git Repo"
              value={values.git_repo}
              error={Boolean(touched.git_repo && errors.git_repo)}
              fullWidth
              helperText={touched.git_repo && errors.git_repo}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            </Grid>

            <Grid container={isMobile?false:true} spacing={6}>
          
            <Grid item md={6}>
            <TextField
              type="text"
              name="git_branch"
              label="Git Branch"
              value={values.git_branch}
              error={Boolean(touched.git_branch && errors.git_branch)}
              fullWidth
              helperText={touched.git_branch && errors.git_branch}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            <Grid item md={6}>
            <TextField
              type="text"
              name="git_token"
              label="Git Token"
              
              value={values.git_token}
              error={Boolean(touched.git_token && errors.git_token)}
              fullWidth
              helperText={touched.git_token && errors.git_token}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
            </Grid>
        

        
          <Button type="submit" disabled={isSubmitting} fullWidth={isMobile?true:false} variant="contained" color="primary">
          Update Api Doc Access
          </Button>
         
  </CardContent>  
  </Card>        
          
        </form>
      )}
    </Formik>
  );
}


function UpdateApiDocAccess() {
  const navigate=useNavigate()
  const location=useLocation()
  return (
    <React.Fragment>
      <Helmet title="Update Api Doc Access" />
     

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>

      <Link to="#">
         <Button onClick={(e)=>{
        
            navigate("/list-superadmin")
          }}
         >
        List Superadmin       
         </Button>
        </Link>        
        
        <Typography>Update Api Doc Access</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default UpdateApiDocAccess;
