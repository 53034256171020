import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { useNavigate,useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Button,
  Link,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { getGlobalUser, resetPassword } from '../../utils/crud';
import {  Auth } from '@aws-amplify/auth';
import {  toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  'width:auto'

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;


function ResetPassword() {
  const navigate = useNavigate();

  const location=useLocation()


  return (
    <Formik
      initialValues={{
        opassword: "",
        password: "",
        confirmpassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        opassword: Yup.string().max(255).required("Old Password is required"),
        password: Yup.string().max(255).required("New Password is required"),
        confirmpassword: Yup.string().max(255).required("Confirm Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {         

           const user= await Auth.currentUserInfo()
           const email=user.attributes.email   
           console.log("befor calling")        
           const resp=await resetPassword({email:email,opassword:values.opassword,password:values.password})
           console.log("Change password response")
           console.log(resp)
           toast.success('Pasword Changed Successfully !');
         
        } catch (error) {
         
          if(JSON.parse(JSON.stringify(error)).name==='NotAuthorizedException' || JSON.parse(JSON.stringify(error)).name==='UserNotFoundException'){
            const message = error.password || "Incorrect user or password";
            //const notify = () => toast('Incorrect user or password');
            //notify()
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="opassword"
            label="Old Password"
            value={values.opassword}
            error={Boolean(touched.opassword && errors.opassword)}
            fullWidth
            helperText={touched.opassword && errors.opassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <TextField
            type="password"
            name="password"
            label="New Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmpassword"
            label="Enter New Password Again"
            value={values.confirmpassword}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            fullWidth
            helperText={touched.confirmpassword && errors.confirmpassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Change password
          </Button>
        </form>
      )}
    </Formik>
  );
}

function ChangePassword() {
  return (
    <>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}  mb={6} >
        <Link href="/profile">Profile</Link>
        <Typography gutterBottom>
        Change Password
        </Typography> 
        </Breadcrumbs>
        <Divider marginBottom={6}/>
    <React.Fragment>
     
      <Wrapper>
          <Helmet title="Reset Password" />
         

       
        <Typography component="h1" variant="h4" align="center" gutterBottom>
        Change Password

        </Typography>
        <Typography component="h2" variant="body1" align="center">
        Please enter your new password below.
        </Typography>

<div style={{maxWidth:'450px',margin:'0px auto'}}>
<ResetPassword />
</div>
        
      </Wrapper>
    </React.Fragment>
    </>

  );
}

export default ChangePassword;
