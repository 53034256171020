import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid

} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { Route, useNavigate,useLocation,NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import { RowingSharp } from '@mui/icons-material';
import {  toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip } from '@mui/material';
import { Helmet } from "react-helmet-async";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function createData(id,connected_tenant,connected_type, tenant , type,  created_at,created_by) {
    return { id,connected_tenant,connected_type, tenant , type, created_at,created_by };
}

function DataTable() {
const navigate = useNavigate()
const location =useLocation()
const [uiRows,setUiRows]=React.useState([])

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredData = uiRows.filter((row) => {
      return (
        row.title.toLowerCase().includes(keyword) ||
        row.description.toLowerCase().includes(keyword)
        
      );
    });
    setFilteredRows(filteredData);
  };

  const handleContextMenuOpen = (event, row) => {
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentToken,setCurrentToken]=useState('')
  const [nextToken,setNextToken]=useState('')
  const [previousToken,setPreviousToken]=useState('')
  
  
  
  /********Added for get data from serever */
  

  
  let R=0;
  
  async function fetchMyAPI() {
   
    const rows=[];
    const query = `
    query ListEnterpriseConnections {
      listEnterpriseConnections(tenant: \"${location.state.data.tenant}\") {
        deleted_at
        connected_tenant
        connected_type
        created_at
        created_by
        tenant
        type
        updated_at
        updated_by
      }
    }
        `;  

console.log(location.state.tenant)        

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse?.data?.listEnterpriseConnections?.map((item)=>{
    rows.push(
      createData( 
      i,
      item.connected_tenant,
      item.connected_type,
      item.tenant,   
      item.type,
      item.created_at,
      item.created_by 
    
    ),
    )
   i++;
    
    })
    setCurrentToken('')  
    setPreviousToken('')
    setNextToken(graphqlResponse?.data?.listEnterpriseConnections?.nextToken)      
    console.log("Current Tone::"+graphqlResponse?.data?.listEnterpriseConnections?.nextToken)
    setUiRows(rows)
    setFilteredRows(rows)
    
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse?.data?.listEnterpriseConnections)
}


async function fetchDataWithToken(token) {
  setPreviousToken(currentToken) 
  const rows=[]
  const query = `
  query ListNotifications {
      listNotifications(limit: 50, nextToken: \"${token}\") {
        data {
          created_at
          created_by
          description
          link
          notification_id
          title
          updated_at
          updated_by
        }
        nextToken
      }
    }
      `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse.data.listNotifications.data.map((item)=>{
  rows.push(
    createData( 
    i,
    item.notification_id,
    item.title,
    item.link,   
    item.description,
    item.created_at,
    item.created_by 
  
  ),
  )
 i++;
  
  })
  
  
  setCurrentToken(token)  
  setNextToken(graphqlResponse.data.listNotifications.nextToken)     
  console.log("Current Tone::"+graphqlResponse.data.listNotifications.nextToken)
  setUiRows(rows)
  setFilteredRows(rows)
  
  console.log("Data rows" + JSON.stringify(rows))
  console.log("Graph ql rESPONSE=")
  console.log(graphqlResponse.data.listNotifications)
}

  useEffect(()=>{
    console.log(location.state.data)
    if(R>0) {R++;return;}
    if(location.state!=null){     
      fetchMyAPI()
    }       
       
    else{
    navigate('/all-account')
    }
  
  },[])
  
  
  /***************End of the fetch data */

  const columns = [  
    { field: 'connected_type', headerName: 'Role',sortable: false,disableColumnMenu: true,minWidth: 150 ,flex: 1},
    { field: 'tenant', headerName: 'To Enterprise',sortable: false,disableColumnMenu: true,flex: 1,minWidth: 200,
  
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
        {params.value}
      </div>
    ),
  },
    { field: 'type', headerName: 'Role',sortable: false,disableColumnMenu: true,flex: 1,flexWrap: 'wrap',
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },   
    { field: 'action', headerName: 'Action',sortable: false,disableColumnMenu: true, minWidth: 100,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
        console.log("Notification ID=")
        console.log(params.row.notification_id)
        handleClickOpen(params.row)
        //handleContextMenuOpen(e, params.row);
      };
  
      return (
        <>
         <Button onClick={onClick} className='bi-trash'><DeleteIcon sx={{color: 'grey'}} /></Button>
        </>
     
      );
    }
  
  },
  ];

  
  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState([])
  const [requestFlag,setRequestFlag]=useState(false)
  
  const handleClickOpen = (id) => {
    setDelItem(id)
    setOpen(true);
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){
      console.log('Deleted Item')
      console.log(delItem)
      //alert(deleteItem)
      await deleteRecords(delItem)
     
    }else{
      setOpen(false)
    }
    //alert(confirm)
    ;
  };

const deleteRecords=async(id)=>{
  setRequestFlag(true)
  try{
    const mutation = `
    mutation DeleteEneterpriseConnection {
      deleteEnterpriseConnection(connected_tenant: \"${id.connected_tenant}"\, tenant: \"${id.tenant}"\, debugFunctions: "", connected_role: \"${id.connected_type}"\, role: \"${id.type}"\) {
        ... on DeletedEnterpriseConnection {
          __typename
          connected_tenant
          connected_type
          tenant
          type
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    } 
        `;  

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse?.data?.deleteEnterpriseConnection.code)

if(graphqlResponse?.data?.deleteEnterpriseConnection?.code){
  toast.error(graphqlResponse.data.deleteEnterpriseConnection.code);
  setOpen(false)
 return 
}

setOpen(false)
let tmpRows=[]
for(let i=0;i<filteredRows.length;i++){
 if(filteredRows[i].connected_type==id.connected_tenant){
 }else{
   tmpRows.push(filteredRows[i])
 }
}
setRequestFlag(false)
setFilteredRows(tmpRows);
toast.success('Records Deleted Successfully !');


  }catch(err){

    console.log("err")
    console.log(err)

  }
   
}
const [page, setPage] = useState(1);
const [rowsPerPage, setRowsPerPage] = useState(2);

// Your data source (replace with your own data source)
const allData = [
  // ... your data
];

// Calculate the data for the current page
const startIndex = (page - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const data = filteredRows.slice(startIndex, endIndex);
  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>      
        <ListItem button>
          <ListItemText onClick={e=>handleClickOpen(selectedRow.notification_id)} primary={`Delete`} />
        </ListItem>
      </List>
    </Popover>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  const customLocaleText = {
    noRowsLabel: 'No recods found ', // Customize the "No rows" message
  };
  return (
    <>
     <Helmet title="List Enterprise Connection" />
       <Breadcrumbs aria-label="Breadcrumb" mt={2}>       
        <Link component={NavLink} to="/all-account">
          All Accounts
        </Link>
       
        <Typography>List Enterprise Connection</Typography>
      </Breadcrumbs>
      <Divider my={6} />
    <div style={{ width: '100%' }}>
     

      <Card mb={6}>
      
        
        <CardContent>
        <Grid  spacing={12}> 
      <Grid item>
        Enterprise Connections of {location.state.data.enterprise_name}
        </Grid>
        <Grid item>
        <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
        <Button mr={2} variant="contained" color="primary" onClick={e=>navigate('/create-enterpriseconnection',{state:{data:location.state.data}})}>
          + Add
        </Button>
        </div>
        </Grid>
      </Grid>


        <DataGrid
        paddingTop='10px'
        rows={filteredRows}
        columns={columns}
        localeText={customLocaleText}
        fullWidth={true}
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#fff',
            fontSize:'15px',
            fontWeight:'bold'
          },
        }} 
        autoHeight  
        pageSizeOptions={[5,10,15]}
        components={{
          Toolbar: () => null, 
          ColumnHeaderMenu: () => null, 
          ColumnsPanel: () => null, 
          Export: () => null,
          Sortable:()=>null,
          Pagination: ()=>null        
        }}
        onCellClick={(params, event) => {
          
        }}
      />
        
          <Paper mt={2}>
          
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </CardContent>
      </Card>  


     
    </div>
    </>
  );
}

export default DataTable;
