import React from "react";

import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
//added by rakesh sinha
import IndexLogin from "./pages/auth/IndexLogin";
import VerifyOtp from "./pages/auth/VerifyOtp";
import CreateAccount from "./pages/pages/CreateAccount";
import UpdateAccount from "./pages/pages/UpdateAccount"

import AllAccounts from "./pages/pages/AllAccounts";
import ManageRole from "./pages/pages/ManageRole";
import AddRole from "./pages/pages/AddRoles";
import CreateLiveRetailer from "./pages/pages/CreateLiveRetailer";
import CreateStore from "./pages/pages/CreateStore";
import CreateSharingApp from "./pages/pages/CreateSharingApp";
import CreateRole from "./pages/pages/CreateRole";
import CreateNotification from "./pages/pages/CreateNotification";
import CreateEnterpriseConnection from "./pages/pages/CreateEnterpriseConnection";
import ListLiveRetailers from "./pages/pages/ListLiveRetailers";
import ListSharingApps from "./pages/pages/ListSharingApps";
import ListLiveStores from "./pages/pages/ListLiveStores";
import CustomTable from "./pages/pages/CustomTable";
import ListViewNotification from "./pages/pages/ListViewNotification";
import ViewEnterpriseRequest from "./pages/pages/ViewEnterpriseRequest";
import ListEnterpriseConnection from "./pages/pages/ListEnterpriseConnection";
import CreateEnterpriseUser from "./pages/pages/CreateEnterpriseUser";
import LIstEnterpriseUser from "./pages/pages/LIstEnterpriseUser";
import ListSuperadmin from "./pages/pages/ListSuperadmin";
import ViewEnterpriseDetails from "./pages/pages/ViewEnterpriseDetails";
import SetupPassword from "./pages/auth/SetupPassword";
import ChangePasword from "./pages/auth/ChangePassword";
import ForgotPasswordSubmit from "./pages/auth/ForgotPasswordSubmit";
import UpdateApiDocAccess from "./pages/pages/UpdateApiDocAccess";
import ListGitOwners from "./pages/pages/ListGitOwners";


// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));


const Profile = async(() => import("./pages/pages/Profile"));



const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <IndexLogin />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },     
      {
        path: "create-account",
        element: <CreateAccount />,
      },
      {
        path: "all-account",
        element: <AllAccounts />,
      },
      {
        path: "live-retailers",
        element: <ListLiveRetailers />,
      },
      {
        path: "live-stores",
        element: <ListLiveStores />,
      },
      {
        path: "sharing-apps",
        element: <ListSharingApps />,
      },
      {
        path: "manage-role",
        element: <ManageRole />,
      },
      {
        path: "add-role",
        element: <AddRole />,
      },
      {
        path: "create-liveretailer",
        element: <CreateLiveRetailer />,
      },
      {
        path: "create-sharingapp",
        element: <CreateSharingApp />,
      },
      {
        path: "create-store",
        element: <CreateStore />,
      },
      {
        path: "create-role",
        element: <CreateRole />,
      },
      {
        path: "create-notification",
        element: <CreateNotification />,
      },
      {
        path: "create-enterpriseconnection",
        element: <CreateEnterpriseConnection />,
      },
      {
        path: "customtable",
        element: <CustomTable/>,
      },
      {
        path: "change-password",
        element: <ChangePasword/>,
      },
      {
        path: "list-notification",
        element: <ListViewNotification/>,
      },
      {
        path: "list-notification",
        element: <ListViewNotification/>,
      },
      {
        path: "view-enterpriserequest",
        element: <ViewEnterpriseRequest/>,
      }, 
      {
        path: "list-enterpriseconnection",
        element: <ListEnterpriseConnection/>,
      },
      {
        path: "create-enterpriseuser",
        element: <CreateEnterpriseUser/>,
      }, 
      {
        path: "list-enterpriseuser",
        element: <LIstEnterpriseUser/>,
      },
      {
        path: "view-enterprisedetails",
        element: <ViewEnterpriseDetails/>,
      },
      {
        path: "list-superadmin",
        element: <ListSuperadmin/>,
      },       
      {
        path: "update-account",
        element: <UpdateAccount/>,
      },
      {
        path: "update-apidocaccess",
        element: <UpdateApiDocAccess/>,
      },
      /*{
        path: "list-gitowners",
        element: <ListGitOwners/>,
      },*/
      
      
    ],
  },  
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "setup-password",
        element: <SetupPassword/>,
      },
      {
        path: "set-forgotpassword",
        element: <ForgotPasswordSubmit/>,
      },
      {
        path: "verify-otp",
        element: <VerifyOtp />,
      },
    ],
  },
  {
    path: "private",
    element: (      
        <DashboardLayout />
    ),    
  },

];

export default routes;
