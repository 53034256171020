import React,{useState} from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Box,
  Alert as MuiAlert,
  CircularProgress,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues = {
  couponUser: "",
  aurthorizedPartner1: "",
  aurthorizedPartner2: "",
  aurthorizedPartner3: "", 
};

const validationSchema = Yup.object().shape({
  couponUser: Yup.string().required("Required"),
  aurthorizedPartner1: Yup.string().required("Required"),
  aurthorizedPartner2: Yup.string().required("Required"),
  aurthorizedPartner3: Yup.string().required("Required"),
 
});



function EmptyCard() {
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await timeOut(1500);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const handleCheckboxChange = (e) => {
    if(e.target.name=='lbl1')
    setIsChecked1(!isChecked1);
    else if(e.target.name=='lbl2')
    setIsChecked2(!isChecked2);
    else if(e.target.name=='lbl3')
    setIsChecked3(!isChecked3);
  };
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
          <Grid container spacing={6}>
        <Grid item md={6}>
        <Typography component="h1" variant="h3"  gutterBottom>
           Pg.thecouponbureau.org
        </Typography>
        <Typography component="h1"  variant="body1" >
        Proctor & Gamble
        </Typography>
        <Typography component="h1"  variant="body1" >
        Description
        </Typography>
        <Typography component="h1"  variant="body1" >
        Account created = 23rd Jan 2023
        </Typography>
         
        </Grid>
        <Grid item md={6}>
          <Breadcrumbs aria-label="Breadcrumb" mt={8}>
          <Link component={NavLink} to="#">
              /Manage Role
            </Link>
          </Breadcrumbs>        
        </Grid>      
      </Grid>
         

            {status && status.sent && (
              <Alert severity="success" my={3}>
                [DEMO] Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      name="couponUser"
                      label="Coupon Issuer"
                      value={values.couponUser}
                      error={Boolean(touched.couponUser && errors.couponUser)}
                      fullWidth
                      helperText={touched.couponUser && errors.couponUser}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  </Grid>

                  <Grid container spacing={6} style={{marginTop:0}}>
                  <Grid item md={6}>

                    <label>
                      <input
                        type="checkbox"
                        name="lbl1"
                        checked={isChecked1}
                        onChange={handleCheckboxChange}
                      />
                      Authorized Partner Level 1
                    </label>
                    {/*<TextField
                      name="aurthorizedPartner1"
                      label="Authorized Partner Level 1"
                      value={values.aurthorizedPartner1}
                      error={Boolean(touched.aurthorizedPartner1 && errors.aurthorizedPartner1)}
                      fullWidth
                      helperText={touched.aurthorizedPartner1 && errors.aurthorizedPartner1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                  / >*/}
                  </Grid>
                </Grid>

                <Grid container spacing={6} style={{marginTop:0}}>
                  <Grid item md={6}>
                  <label>
                      <input
                        type="checkbox"
                        name="lbl2"
                        checked={isChecked2}
                        onChange={handleCheckboxChange}
                      />
                      Authorized Partner Level 2
                    </label>
                    {/*<TextField
                      name="aurthorizedPartner2"
                      label="Authorized Partner Level 2"
                      value={values.aurthorizedPartner2}
                      error={Boolean(touched.lastName && errors.aurthorizedPartner2)}
                      fullWidth
                      helperText={touched.aurthorizedPartner2 && errors.aurthorizedPartner2}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                />*/}
                  </Grid>
                </Grid>

                <Grid container spacing={6} style={{marginTop:0}}>
                  <Grid item md={6}>
                  <label>
                      <input
                        type="checkbox"
                        name="lbl3"
                        checked={isChecked3}
                        onChange={handleCheckboxChange}
                      />
                      Authorized Partner Level 3
                    </label>
                    {/*<TextField
                      name="aurthorizedPartner3"
                      label="Authorized Partner Level 3"
                      value={values.aurthorizedPartner3}
                      error={Boolean(touched.aurthorizedPartner3 && errors.aurthorizedPartner3)}
                      fullWidth
                      helperText={touched.aurthorizedPartner3 && errors.aurthorizedPartner3}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
              />*/}
                  </Grid>
                </Grid>

                {/*<TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="confirmPassword"
                  label="Confirm password"
                  value={values.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  my={2}
                  />*/}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
    
  );
}


function ManageRole() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
     

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/all-account">
              All Accounts
            </Link>
        <Typography>Manage Role</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

      
    </React.Fragment>
  );
}

export default ManageRole;
