import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,


} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { Route, useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import { RowingSharp } from '@mui/icons-material';
import {  toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import {isMobile } from 'react-device-detect';
import { Helmet } from "react-helmet-async";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
function createData(id,app_name, destination_mode,image_url,landing_page,tenant, terms , created_at,created_by) {
  return { id,app_name, destination_mode,image_url,landing_page, tenant,terms, created_at,created_by };
}
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function DataTable() {

  const [records, setRecords] = useState([]);
  const [pages,setPages]=useState(1)
  
  const addRecord = (newRecord) => {
    console.log("Add New Rocrods")
    
    setRecords([...records, newRecord]);
    console.log(records)
  };
  const deleteRecord = async (index) => {
    const updatedRecords = [...records];
    updatedRecords.splice(index, 1);
    setRecords(updatedRecords);
  };
  
  /********End of Global Variable SEction ******/

const navigate = useNavigate()
const [uiRows,setUiRows]=React.useState([])

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredData = uiRows.filter((row) => {
      return (
        row.title.toLowerCase().includes(keyword) ||
        row.description.toLowerCase().includes(keyword)
        
      );
    });
    setFilteredRows(filteredData);
  };

  const handleContextMenuOpen = (event, row) => {
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentToken,setCurrentToken]=useState('')
  const [nextToken,setNextToken]=useState('')
  const [previousToken,setPreviousToken]=useState('')
  const [requestFlag,setRequestFlag]=useState(false)

  /********Added for get data from serever */
  
  let acccessToken=null
  
  let R=0;
  
  async function fetchMyAPI() {
    setRequestFlag(true)
    const rows=[];
    const query = `
    query MyQuery {
      listSharingApps(limit: 50, nextToken: "") {
        data {
          app_installed_api
          app_name
          created_at
          created_by
          destination_mode
          image_url
          landing_page
          tenant
          terms
          updated_at
          updated_by
        }
        nextToken
      }
    }
        `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse.data.listSharingApps.data.map((item)=>{
    rows.push(
      createData( 
        i,    
        item.app_name,
        item.destination_mode,
        item.image_url,
        item.landing_page,
        item.tenant,   
        item.terms,
        item.created_at,
        item.created_by 
    
    ),
    )
   i++;
    
    })

    
    setCurrentToken('')  
    setPreviousToken('')
    setNextToken(graphqlResponse.data.listSharingApps.nextToken)      
    console.log("Current Tone::"+graphqlResponse.data.listSharingApps.nextToken)
    setUiRows(rows)
    setFilteredRows(rows)
    setRequestFlag(false)
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse.data.listSharingApps)
}


async function fetchDataWithToken(token) {
  setPreviousToken(currentToken) 
  const rows=[]
  const query = `
  query ListLiveStores {
    listLiveStores(limit: 50, nextToken: "") {
      data {
        created_at
        created_by
        description
        retailer_id
        retailer_logo
        retailer_name
        store_locator_url
        updated_at
        updated_by
      }
      nextToken
    }
  }
      `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse?.data?.listLiveStores?.data?.map((item)=>{
  rows.push(
    createData( 
      i,    
      item.retailer_logo,
      item.retailer_name,
      item.store_locator_url,
      item.description,
      item.retailer_id,   
      item.deactivated,
      item.created_at,
      item.created_by , 
  
  ),
  )
 i++;
  
  })
  
  
  setCurrentToken(token)  
  setNextToken(graphqlResponse?.data?.listLiveStores?.nextToken)     
  console.log("Current Tone::"+graphqlResponse?.data?.listLiveStores?.nextToken)
  setUiRows(rows)
  setFilteredRows(rows)
  
  console.log("Data rows" + JSON.stringify(rows))
  console.log("Graph ql rESPONSE=")
  console.log(graphqlResponse?.data?.listLiveStores)
}

  useEffect(()=>{
    if(R>0) {R++;return;}       
    fetchMyAPI()
  
  },[])
  
  
  /***************End of the fetch data */

  const columns = [
    { field: 'image_url', headerName: 'Image', minWidth: 80,sortable: false,disableColumnMenu: true, flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',
    renderCell: (params) => (
      <div >
        <img  src={params.value} style={{borderRadius: '50%',height:'36px',width:'36px'}}/>
        
      </div>
    )
    },   
    
    { field: 'app_name', headerName: 'App Name',minWidth: 80,sortable: false,disableColumnMenu: true ,flex: 1,headerClassName: 'super-app-theme--header',
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
    },    
    { field: 'destination_mode', headerName: 'Destination Mode',minWidth: 80,sortable: false,disableColumnMenu: true ,flex: 1,headerClassName: 'super-app-theme--header',
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
    },
    { field: 'landing_page', headerName: 'Landing Page',minWidth: 100,sortable: false,disableColumnMenu: true ,flex: 1,headerClassName: 'super-app-theme--header',
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit' }}>
         <a href={params.value} target='_blank'><LinkIcon /></a>       
      </div>
    ),
    },
    { field: 'tenant', headerName: 'Enterprise',minWidth: 150,sortable: false,disableColumnMenu: true ,flex: 1,headerClassName: 'super-app-theme--header',  
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit' }}>
        {params.value}
      </div>
    ),
    },       
    
    { field: 'terms', headerName: 'Terms',flexWrap: 'wrap',sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header',
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
         <a href={params.value} target='_blank'><LinkIcon /></a>
      </div>
    ),
  },   
    { field: 'action', headerName: 'Action', minWidth: 100, sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header', 
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
        console.log(params.retailer_id)
        handleContextMenuOpen(e, params.row);
      };
  
      return (
        <>
          <Button onClick={onClick} className='bi-trash'><DeleteIcon sx={{color: 'grey'}} /></Button>
         
        </>
     
      );
    }
  
  },
  ];


  
  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()
 
  const handleClickOpen = (id,otype) => {
    setContextMenuAnchor(null);
    setDelItem(id)
    console.log("Records")
    console.log(id)
   
      setOpen(true);
      
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){
    
      await deleteRecords(delItem)
     
    }else{
      setOpen(false)
    }
    //alert(confirm)
    ;
  };

const deleteRecords=async(id)=>{ 
  try{
        const mutation = `
        mutation DeleteSharingApp {
          deleteSharingApp(tenant: \"${id}\") {
            ... on DeletedSharingApp {
              __typename
              tenant
            }
            ... on CustomError {
              error
              message
              code
            }
          }
        }  
            `;  

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse?.data?.deleteSharingApp.code)

if(graphqlResponse?.data?.deleteSharingApp?.code){
  toast.error(graphqlResponse.data.deleteSharingApp.code);
  setOpen(false)
 return 
}

setOpen(false)
let tmpRows=[]
for(let i=0;i<filteredRows.length;i++){
 if(filteredRows[i].tenant==id){
 }else{
   tmpRows.push(filteredRows[i])
 }
}
setFilteredRows(tmpRows);
toast.success('Records Deleted Successfully !');


  }catch(err){

    console.log("err")
    console.log(err)

  }
   
}
const [page, setPage] = useState(1);
const [rowsPerPage, setRowsPerPage] = useState(2);

// Your data source (replace with your own data source)
const allData = [
  // ... your data
];

// Calculate the data for the current page
const startIndex = (page - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const data = filteredRows.slice(startIndex, endIndex);
  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
          
        <ListItem button>         
           <ListItemText onClick={e=>handleClickOpen(selectedRow.tenant,'Delete')} primary={`Delete`} />
        </ListItem>
      </List>
    </Popover>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  //miui pagination handling
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2; 
  const data1 = filteredRows; 
  const pageCount = Math.ceil(data1.length / itemsPerPage);
  const startPage = (currentPage - 1) * itemsPerPage;
  const endPage = startPage + itemsPerPage;
  const currentPageData = data1.slice(startPage, endPage);


  const handlePageChange =async(event, page) => {
    //alert(page + ":" + records.length)   
    if(records.length>=1){     
      if(page===1){
        for(let i=0;i<records.length;i++){
          deleteRecord(records.length-i) 
        }
        //alert(records.length)        
        fetchMyAPI()        
      }else{
        //alert(records.length) 
        fetchDataWithToken(records[page-2].token,page)  
      }      
      setCurrentPage(page);
    }
  };

  return (
    <>
    <Helmet title="List Sharing Apps" />
     <Breadcrumbs aria-label="Breadcrumb" mt={2}>
    <Typography component="h1" variant="h5" align="center" gutterBottom>
    Sharing App
    </Typography> 
    </Breadcrumbs>
    <Divider my={6} />
    
   
    <div style={{ width: '100%' }}>
    
      <Card mb={6}>
        <CardContent>

        <Grid container spacing={12}>
        <Grid item >
        
      
        </Grid>
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >
       
        <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
        <Button mr={2} variant="contained" color="primary" onClick={e=>navigate('/create-sharingapp')}>
          + Add
        </Button>
      </div>
        </Grid>

          
        </Grid>   

     
      
      <DataGrid
        paddingTop='10px'
        rows={filteredRows}
        columns={columns}
        fullWidth={true}
        autoHeight 
        sx={{
        
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#fff',
            fontSize:'15px',
            fontWeight:'bold'
          },
        }}      
          
        pageSizeOptions={[5,10,15]}
        components={{
          Toolbar: () => null, 
          ColumnHeaderMenu: () => null, 
          ColumnsPanel: () => null, 
          Export: () => null,
          Pagination: ()=>  {
            return (
              <div>            
              {/* Pagination component */}
              <Stack direction="row" justifyContent="center">
                <Pagination
                  count={records.length+1}
                  page={currentPage}
                  onChange={handlePageChange}
                  boundaryCount={10} // Adjust as needed
                  showFirstButton
                  showLastButton
                  renderItem={(item) => (
                    <PaginationItem
                      component="div"
                      {...item}
                    />
                  )}
                />
              </Stack>
            </div>  
            )},
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {requestFlag==true? <div style={{textAlign:'center'}}>
                      <img style={{width:'48px'}} src="/static/img/brands/loading.gif"/>          
                    </div>:'No Records Found'}
                
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Local filter returns no result
              </Stack>
            )        
        }}
        onCellClick={(params, event) => {
          
        }}
      />
      {contextMenu}     
          
        
          <Paper mt={0}>
          
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </CardContent>
      </Card>  


     
    </div>
    </>
  );
}

export default DataTable;
