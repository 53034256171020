import React,{useEffect,useState} from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { logOut } from '../../utils/crud';


import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import {BrowserView, MobileView} from 'react-device-detect';
import { getProfile} from '../../utils/crud';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
    navigate("/profile");
  };

  const handleSignOut = async () => {
    await logOut();
    localStorage.setItem("email", email);
    navigate("/auth/sign-in");
  };
const handleChangepwd =async ()=>{

 navigate("/change-password");
}


const [email,setEmail]=useState('')
const [userid,setUserid]=useState('')

useEffect(()=>{
  getProfile().then(data=>{
    setUserid(data.username)
    setEmail(data.attributes.email)
  })

},[])

  return (
    <React.Fragment>
      <BrowserView>
      {email}
      <Tooltip title="Account"  >
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <div style={{marginRight:'15px'}}>  <ArrowDropDownIcon /></div>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Profile</MenuItem>

        {/*<MenuItem onClick={handleChangepwd}>Change Password</MenuItem>*/}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
      </BrowserView>
      <MobileView>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <ArrowDropDownIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Profile</MenuItem>

        {/*<MenuItem onClick={handleChangepwd}>Change Password</MenuItem>*/}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
      </MobileView>
      
     
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
