import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { useNavigate,useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Avatar,
  Link,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { changePassword } from '../../utils/crud';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 64px;
  height: 64px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function ResetPassword() {
  const navigate = useNavigate();

  const location=useLocation()


  return (
    <Formik
      initialValues={{
        password: "",
        confirmpassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required("New Password is required"),
        confirmpassword: Yup.string().max(255).required("Confirm Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try { 

          console.log(location)


          if(values.password.match(values.confirmpassword)){
            const jsonData={"email":localStorage.getItem("email"),"password":values.password,"confirmpassword":values.confirmpassword}            
            const resp=changePassword(jsonData) 
                      
            if(resp.challengeName=='NEW_PASSWORD_REQUIRED'){
              localStorage.setItem("password",values.password)
               navigate("/auth/setup-password");           
             }else {              
                navigate("/auth/sign-in");             
             } 
          }else{
            setStatus({ success: false });
            setErrors({ submit: 'Passwords mismatch!' });
            setSubmitting(false);
          }


          //changePassword(values.email);
          //navigate("/auth/sign-in");
        } catch (error) {
         
          if(JSON.parse(JSON.stringify(error)).name==='NotAuthorizedException' || JSON.parse(JSON.stringify(error)).name==='UserNotFoundException'){
            const message = error.password || "Incorrect user or password";
            //const notify = () => toast('Incorrect user or password');
            //notify()
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="password"
            name="password"
            label="New Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmpassword"
            label="Enter New Password Again"
            value={values.confirmpassword}
            error={Boolean(touched.confirmpassword && errors.confirmpassword)}
            fullWidth
            helperText={touched.confirmpassword && errors.confirmpassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Change password
          </Button>
        </form>
      )}
    </Formik>
  );
}

function SetupPassword() {
  return (
    <React.Fragment>
   
      <Wrapper>
      <Link href="/auth/sign-in"><ArrowBackIcon/></Link>&nbsp;&nbsp;&nbsp;&nbsp;<Link href="/"><HomeIcon/></Link>
        <Helmet title="Reset Password" />
        
        <BigAvatar alt="Lucy" src="/static/img/brands/TCB_Blue_Bug.png" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
        Change Password

        </Typography>
        <Typography component="h2" variant="body1" align="center">
        Please enter your new password below.
        </Typography>

        <ResetPassword />
      </Wrapper>
    </React.Fragment>
  );
}

export default SetupPassword;
