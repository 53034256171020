import React from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Box,
  Alert as MuiAlert,
  CircularProgress,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues = {
  couponUser: "",
  aurthorizedPartner1: "",
  aurthorizedPartner2: "",
  aurthorizedPartner3: "", 
};

const validationSchema = Yup.object().shape({
  couponUser: Yup.string().required("Required"),
  aurthorizedPartner1: Yup.string().required("Required"),
  aurthorizedPartner2: Yup.string().required("Required"),
  aurthorizedPartner3: Yup.string().required("Required"),
 
});



function EmptyCard() {
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await timeOut(1500);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const [personName, setPersonName] = React.useState([]);
  const names = [
    "Super Admin",
    "Admin",
    "Operator",
    "Users",
    
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
          <Grid container spacing={6}>
        <Grid item md={6}>
        <Typography component="h1" variant="h4"  gutterBottom>
          Add Role
        </Typography>
       
         
        </Grid>
              
      </Grid>
         

            {status && status.sent && (
              <Alert severity="success" my={3}>
                [DEMO] Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
 <Grid container spacing={6}>
        <Grid item md={6}>
          <TextField
            id="username"
            label="Identity"
            defaultValue="PG"
            variant="outlined"            
            fullWidth
            my={4}
          />      
        </Grid>
        <Grid item md={6}>
          <Breadcrumbs aria-label="Breadcrumb" mt={8}>
              <Typography variant="h6" gutterBottom>
              .thecouponbureau.org
              </Typography>
          </Breadcrumbs>        
        </Grid>      
      </Grid>

                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      name="adminEmail"
                      label="Admin Email"
                      value={values.couponUser}
                      error={Boolean(touched.couponUser && errors.couponUser)}
                      fullWidth
                      helperText={touched.couponUser && errors.couponUser}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                  <Grid item md={6}>
                  <FormControl m={2}>
                    <InputLabel id="demo-mutiple-name-label">Add Roles</InputLabel>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      style={{width:250}}
                      multiple              
                      value={personName}
                      onChange={handleChanges}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>   
                    
                  </Grid>
                </Grid>


                {/*<TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="confirmPassword"
                  label="Confirm password"
                  value={values.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  my={2}
                  />*/}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
    
  );
}


function ManageRole() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
     

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/all-account">
              All Accounts
            </Link>
        <Typography>Add Role</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

      
    </React.Fragment>
  );
}

export default ManageRole;
