 export const awsExports = {
    "REGION" : "us-east-1",
    "USER_POOL_ID": "us-east-1_uKkSdzlMg",
    "USER_POOL_APP_CLIENT_ID": "5vm1kl56bru1otn0ukjtltinc6",
    "AWS_APPSYNC_GRAPHQULENDPOINT":"https://dev.superadmin.tcbapi.org/graphql"

}



/*export const awsExports = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-2:cec55e7d-2e27-4e4d-800a-8924b3d36eb8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hIDtJZZok",
    "aws_user_pools_web_client_id": "4219m8pdpbuqef2kt905gbuos4",
    "oauth": {
        "domain": "amplifyauthweb-local.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.amplifyauth.dev?signedin=true",
        "redirectSignOut": "https://www.amplifyauth.dev/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};*/