import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography,Box,Avatar } from "@mui/material";
import OtpInput from "react-otp-input";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
//import IndexLoginComponent from "../../components/auth/IndexLogin";
import logo from '../../vendor/output.png';

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { IsLoggedIn} from '../../utils/crud';
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
  text-align: "center";
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};    
  }
`;
const BigAvatar = styled(Avatar)`
  width: 64px;
  height: 64px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function LoginIndex() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [onSession,setOnSession]=useState(true)

  useEffect(()=>{ 
    isLoggedIn()
  },[])

  const isLoggedIn=async()=>{
    try
    {
      setOnSession(true)
      const resp=await IsLoggedIn()
      console.log("Is Logged In")
      console.log(resp)
      if(resp){
        navigate('/all-account')
        return
      }
    }catch(err){  
      console.log(err)   

    }
    setOnSession(false)
  }

  return (
    <>
      <React.Fragment> 
      <Wrapper>
      {onSession==false?<>
        <Helmet title="Authencation" />  
        <BigAvatar alt="Lucy" src="/static/img/brands/TCB_Blue_Bug.png" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Enter email to continue
        </Typography>  
        <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          //resetPassword(values.email);
          localStorage.setItem("email", values.email);
          navigate("/auth/sign-in/");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
            
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Next
          </Button>
        </form>
      )}
    </Formik></>:<>
    <Typography component="h1" variant="h4" align="center" gutterBottom>
    Checking Authencation...
    </Typography>
    </>
    }
    
    </Wrapper>
    </React.Fragment>
    </>
  );
}
export default LoginIndex;

/*function LoginIndex() {
  return (
    <React.Fragment> 
      <Wrapper>
        <Helmet title="Authencation" />  
        <BigAvatar alt="Lucy" src="/static/img/brands/TCB_Blue_Bug.png" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Enter email to continue
        </Typography>
        <IndexLoginComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default LoginIndex;*/
