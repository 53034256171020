import React,{useEffect, useState} from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,

  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { createItem,getListItems,deleteItem } from '../../utils/crud';
import Select from 'react-select'
import zIndex from "@mui/material/styles/zIndex";

import { useSSR } from "react-i18next";
import {  toast } from 'react-toastify';
import Modal from '@mui/material/Modal';

const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function EmptyCard() {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  const navigate = useNavigate();

  const location=useLocation();
  const [personName, setPersonName] = React.useState([]);
  const [listRoles,setListRoles]=React.useState([])

  function createData(value,label) {
    return { value,label };
}

  let R=0; 
  useEffect(()=>{
    if(location?.state==null){
      navigate('/all-account')
        }
    if(R>0) {R++;return;}       
    fetchMyAPI()
  
   
  },[])


  async function fetchMyAPI() {
    await getRoles()
    console.log("2. Users Roles=")
   
    const rows=[]
    const query = `
    query MyQuery {
      listRoles
    }
  `; 

  const graphqlResponse =  await getListItems(query) 
  const rowsInfo=graphqlResponse.data.listRoles;
  rowsInfo.map((item)=>{
    rows.push(createData(
      item,
      item

    ))

    })
  setListRoles(rows)
  console.log("Rows Info=")
  console.log(rows) 

  }

  const [roleofTenant,setRoleofTenant]=React.useState([])

  const getRoles=async()=>{
    const rows=[]
    const query = `
    query ListEnterpriseRoles {
      listEnterpriseRoles(tenant: \"${location.state.tenant}\") {
        created_at
        created_by
        deleted_at
        role_name
        tenant
        updated_at
        updated_by
      }
    }
        `;  

  const graphqlResponse =  await getListItems(query)
  console.log(graphqlResponse)

  graphqlResponse.data.listEnterpriseRoles.map((item)=>{
  rows.push(item.role_name)
  })
  setRoleofTenant(rows)
  console.log("1. Users Roles=")
  console.log(rows)

  }

  const [isDisable,setIsDisable]=useState(false)
  const deleteEnterpriseRole=async(role)=>{
    setIsDisable(true)  
    const mutation=`mutation DeleteEnterpriseRole {
      deleteEnterpriseRole(role_name: \"${role}\", tenant: \"${location.state.tenant}\") {
        ... on DeletedEnterpriseRole {
          __typename
          role_name
          tenant
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }`

    const graphqlResponse =  await deleteItem(mutation)
    console.log(graphqlResponse)
    const error=graphqlResponse?.data?.deleteEnterpriseRole?.error
    if(error){
      toast.error(graphqlResponse?.data?.deleteEnterpriseRole?.message,{
        autoClose: 1000,
      })
    }else{
      toast.error("Role Deleted Successfully !",{
      autoClose: 1000,
    })
    }
  }

  const createEnterpriseRole=async(role)=>{
    setIsDisable(true)  
    const mutation=`mutation CreateEnterpriseRole {
      createEnterpriseRole(role_name: \"${role}\", tenant: \"${location.state.tenant}\") {
        ... on EnterpriseRole {
          deleted_at
          created_at
          created_by
          role_name
          tenant
          updated_at
          updated_by
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }`

    const graphqlResponse =  await createItem(mutation)
    console.log(graphqlResponse)
    const error=graphqlResponse?.data?.createEnterpriseRole?.error
    if(error){
      toast.error(graphqlResponse?.data?.createEnterpriseRole?.message)
    }else{
      toast.success("Role Updated Successfully !",{
        autoClose: 1000,
      })
    }
  }

 

  const handleChanges = async (event) => {
    //alert(event.target.name)
    if(event.target.checked==true){
    
      await createEnterpriseRole(event.target.name)
      setIsDisable(false)   
    }else{

      await deleteEnterpriseRole(event.target.name)
      setIsDisable(false)  
    }
  };
  
  

  return (
    <Formik 
      initialValues={{
        role_name:'',
        tenant: location.state.tenant,       
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        role_name: Yup.string().max(255).required("Role Name is required"),
        tenant: Yup.string().max(255).required("Tenant is required"),        
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
   
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
       
        
           <Typography variant="h6" gutterBottom>
            Role info
          </Typography>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          

          <Grid container spacing={6}>
            <Grid item md={6}> 
            <TextField
            type="text"
            name="tenant"
            label="Tenant"
            disabled
            value={values.tenant}
            error={Boolean(touched.tenant && errors.tenant)}
            fullWidth
            helperText={touched.tenant && errors.tenant}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item md={6}>           

            <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Assign responsibility</FormLabel>
            {listRoles.length>0 &&
             <FormGroup>
             {listRoles.map((item,index)=>{
              return(             
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked ={roleofTenant.indexOf(item.label)>=0?true:false}                   
                    onChange={handleChanges}
                    disabled={isDisable}
                    name={item.label}
                  />
                }
                label={item.label}
                /> 
                ) 
             })
              
             } 
                      
           </FormGroup>
            }
           
          </FormControl>
            </Grid>
            
                      
          </Grid>

        
          
        </form>
      )}
    </Formik>
  );
}


function CreateRole() {
  return (
    <React.Fragment>
      <Helmet title="Create Role" />
      <Typography variant="h3" gutterBottom display="inline">
        Role
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/all-account">
          All Acounts
        </Link>
               
        <Typography>Create Role</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
          <CardContent>
          <EmptyCard />
          </CardContent>

          </Card>
        
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateRole;
