import React,{useEffect, useState} from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  Box,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import Resizer from "react-image-file-resizer";
import defaultImg from "./logo_icon.png"
import {getListItems,createItem } from '../../utils/crud';
import Autocomplete from '@mui/material/Autocomplete';

const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function EmptyCard() {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);
  const [newImage,setNewImage]=useState(null);
  const [logoUrl,setLogoUrl]=useState('')

  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  

  const uploadIcon=async(_iconBase64)=>{

    let cSession=await Auth.currentSession()
    let acccessToken= cSession.getAccessToken()
    let awtToken= acccessToken.getJwtToken()
  
    Amplify.configure({
      API:{
      graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
      graphql_headers : async () =>({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${awtToken}`, 
  
      })        
    }}) 
    let assetName=Math.ceil(Math.random()*999999).toString().padEnd(6,"0")
    console.log(assetName)
    let finalBase64=_iconBase64.split(',')[1];
    const mutation=`mutation UploadAsset {
      uploadAsset(input: {asset_name: \"${assetName}\", base64_data: \"${finalBase64} \"

    , content_type: "image/jpg", extension: ".jpg"}) {
        ... on Asset {
          __typename
          asset_id
          asset_name
          created_at
          created_by
          public_url
          tenant
          updated_at
          updated_by
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }`

    console.log(finalBase64);


const graphqlResponse = await API.graphql({
  query: mutation,
  authMode: 'AMAZON_COGNITO_USER_POOLS', // Use this if you're using Cognito
  variables: { limit: 10 }, // Your query variables
  headers: {
    Authorization: awtToken,
  },
});

  console.log("Graph ql rESPONSE=")
  console.log(graphqlResponse.data.uploadAsset.public_url)
  setLogoUrl(graphqlResponse.data.uploadAsset.public_url)
	}
  const fileChangedHandler=(event)=>{
   
		var fileInput = false;
		if (event.target.files[0]) {
		  fileInput = true;
		}
		if (fileInput) {
		  try {
			Resizer.imageFileResizer(
			  event.target.files[0],
			  160,
			  160,
			  "JPEG",
			  100,
			  0,
			  (uri) => {
				setNewImage(uri);
				uploadIcon(uri);
			  },
			  "base64",
			  96,
			  96
			);
		  } catch (err) {
			console.log(err);
		  }
		}
	}

  const [options, setOptions] = useState([{lable:'Select Enterprise',value:'Enterprise'}]);
  const [inputValue, setInputValue] = useState();
  const [loading, setLoading] = useState(false);
  
useEffect(()=>{
  fetchAllTenant()
},[])

const [allTenant,setAllTenant]=React.useState([])
const [tenant,setTenant]=React.useState('')

async function  fetchAllTenant() {
  const rows_alltenant=[]
  const query = `
  query MyQuery {
    listEnterprises(limit: 10, nextToken: "") {
      data {
        enterprise_name       
        tenant
        address_info
        website_url
      
      }
    }
    
  } `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)

/*graphqlResponse.data.listEnterprises.data.map((item)=>{
  rows_alltenant.push(item.tenant)
  
})
setAllTenant(rows_alltenant)*/


if(graphqlResponse?.data?.listEnterprises?.data.length>0){
 

  for(let i=0;i<graphqlResponse.data.listEnterprises.data.length;i++){   
  
    rows_alltenant.push({label:graphqlResponse.data.listEnterprises.data[i].enterprise_name,value:graphqlResponse.data.listEnterprises.data[i].tenant})
   
  }
  setOptions(rows_alltenant);
  console.log(options)
}


}


useEffect(() => {
  if (inputValue === '') {
    return;
  }

  //alert(inputValue)
  console.log(inputValue)
  setLoading(true);
  const fetchGraphQLData  = async () => {
  const rows=[]

  try{
    const query = `
    query SearchEnterprise {
      searchEnterprises(limit: 10, searchStr: \"${inputValue}\", nextToken: "") {
        data {
          enterprise_name
            description
            created_at
            created_by
            deactivated
            deleted_at
            tenant
            address_info
            website_url
            updated_at
            updated_by
        }
        nextToken
      }
    }
  `;  
  
const graphqlResponse =  await getListItems(query)

if(graphqlResponse?.data?.searchEnterprises?.data.length>0){

  for(let i=0;i<graphqlResponse.data.searchEnterprises.data.length;i++){   
  
      rows.push({label:graphqlResponse.data.searchEnterprises.data[i].enterprise_name,value:graphqlResponse.data.searchEnterprises.data[i].tenant})
   
  }
}
setOptions(rows);
console.log("Search Ahead => ")
console.log(graphqlResponse.data.searchEnterprises)
} catch (error) {
  console.error('An error occurred:', error);
}finally{
  setLoading(false);
}
}

  fetchGraphQLData ()
 


}, [inputValue]);


const [connectedType,setConnectedType]=useState()
const changeTenant=async (e,latestValue) =>{

  //alert(latestValue?.value)
  setConnectedType(latestValue?.value)

  /*if(connectedType.length==0){
    setIsSelectedType(true)
  } else{
    setOtherTenant(latestValue?.value)
    await finalIntersection(latestValue?.value)
  }*/

}


  return (
    <Formik 
      initialValues={{
        app_installed_api:'',
        app_name:'',
        destination_mode: '',      
        landing_page: '',       
        terms: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        app_installed_api: Yup.string().max(255).required("App Installed Api is required").url("Not a valid url"),
        app_name: Yup.string().max(255).required("App Name is required"),
        destination_mode:Yup.string().max(255).required("Destination Mode is required"),       
        landing_page:Yup.string().max(255).required("Landing Page is required").url("Not a valid url"),       
        terms:Yup.string().max(255).required("Terms is required").url("Not a valid url"),
       
        
      })}

     
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        
        try {
          
          let cSession=await Auth.currentSession()
          let acccessToken= cSession.getAccessToken()
          let awtToken= acccessToken.getJwtToken()

          Amplify.configure({
            API:{
            graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
            graphql_headers : async () =>({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${awtToken}`, 
        
            })        
          }})       
          if(logoUrl.length==0){
            setStatus({ success: false });
            setErrors({ submit:  'Please Upload Logo !'})
            return
          }else{
          
          const mutation=`mutation CreateSharingApp {
            createSharingApp(sharingApp: {app_installed_api:\"${values.app_installed_api.toString()}\",app_name:\"${values.app_name.toString()}\",destination_mode:\"${values.destination_mode.toString()}\",image_url:\"${logoUrl.toString()}\", landing_page:\"${values.landing_page.toString()}\", tenant: \"${connectedType}\", terms: \"${values.terms.toString()}\"}) {
              ... on SharingApp {
                terms
                image_url
                app_installed_api
                app_name
                created_at
                created_by
                destination_mode
                landing_page
                tenant
                updated_at
                updated_by
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }`

          console.log(mutation)
          
          const graphqlResponse = await API.graphql({
            query: mutation,
            authMode: 'AMAZON_COGNITO_USER_POOLS', // Use this if you're using Cognito
            variables: { limit: 10 }, // Your query variables
            headers: {
              Authorization: awtToken,
            },
          });
          
          console.log("Graph ql rESPONSE=")
          console.log(graphqlResponse)
          localStorage.removeItem('tenant')
          if(graphqlResponse.data.createSharingApp.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse.data.createSharingApp.message});
          }else(
            navigate("/sharing-apps")
          )
          }
         
         
        } catch (error) {
          console.log(error)
          

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
  

        <Card mb={6}>
        <CardContent>
  
          <Typography variant="h6"  gutterBottom>
            Sharing App info
          </Typography>

          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container spacing={6}>
          <Grid item md={4} my={10}>
            
            <CenteredContent>
            
              <BigAvatar
                alt="Remy Sharp"
                src={newImage || defaultImg} 
              />
             
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label htmlFor="raised-button-file">
                <Button  variant="contained" color="primary" component="span">
                  
                  <CloudUpload mr={2} /> Select Logo
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </label>
            </CenteredContent>
          </Grid> 
          
          <Grid item md={8}>
            
            <TextField
              id="app_installed_api"
              label="App Installed Api"
              name="app_installed_api"
              value={values.app_installed_api}
              error={Boolean(touched.app_installed_api && errors.app_installed_api)}
              fullWidth
              helperText={touched.app_installed_api && errors.app_installed_api}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />

           
              <TextField
                label="App Name"
                name="app_name"
                id="app_name"
                value={values.app_name}
                error={Boolean(touched.app_name && errors.app_name)}
                fullWidth
                helperText={touched.app_name && errors.app_name}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />          

            <TextField
              type="text"
              id="destination_mode"
              name="destination_mode"
              label="Destination Model"
              value={values.destination_mode}
              error={Boolean(touched.destination_mode && errors.destination_mode)}
              fullWidth
              helperText={touched.destination_mode && errors.destination_mode}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
          />

            <TextField
              type="text"
              id="landing_page"
              name="landing_page"
              label="Landing Page"
              value={values.landing_page}
              error={Boolean(touched.landing_page && errors.landing_page)}
              fullWidth
              helperText={touched.landing_page && errors.landing_page}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
          />


                    <Autocomplete
                          id="server-data-autocomplete"
                          options={options}
                          loading={loading}
                          onChange={changeTenant} 
                          onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                          getOptionLabel={(option) => option.label} // Replace with your data's label property
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Enterprise"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? 'Loading...' : null}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />

           

                {/*(allTenant!=null && allTenant.length>=1)?
                    <>

                  <Box sx={{ minWidth: 120 }}  mt={2}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                      <Select
                        labelId="connectedType"
                        id="connectedType"                    
                        label="Tenant"
                        onChange={e=>setTenant(e.target.value)}                  
                        MenuProps={MenuProps}
                      >
                      {allTenant.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                      </Select>
                    </FormControl>
                    </Box> 
                      
                      </>
                      :
                      <>
                   
                      </>
                      */}

            <TextField
              type="url"
              id="terms"
              name="terms"
              label="Terms"
              value={values.terms}
              error={Boolean(touched.terms && errors.terms)}
              fullWidth
              helperText={touched.terms && errors.terms}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
          />

          <div style={{textAlign:'right'}}>
          <Button type="submit"  disabled={isSubmitting} variant="contained" color="primary">
            Create Sharing App
          </Button>
          </div>
           
          </Grid>
            
          </Grid>

        
         
         
  </CardContent>  
  </Card>        
          
        </form>
      )}
    </Formik>
  );
}


function CreateSharingApp() {
  return (
    <React.Fragment>
      <Helmet title="Create Sharing App" />
      <Typography variant="h3" gutterBottom display="inline">
        Sharing App
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>      
        <Link component={NavLink} to="/sharing-apps">
          List
        </Link>
        <Typography>Create Sharing App</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateSharingApp;
