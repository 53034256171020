
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from './aws-exports';
import OAuth from '@aws-amplify/auth/lib/OAuth/OAuth'
import { API, graphqlOperation,Amplify } from 'aws-amplify';
import { json } from 'react-router-dom';
import { ConstructionOutlined } from '@mui/icons-material';
let globalUser=null
//const [constUser,setConstUser]=React.useState()
const data = [
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    // Add more data as needed
  ];

  export const configureCognito=async ()=>{
    Amplify.configure({
        Auth: {
          region: awsExports.REGION,
          userPoolId: awsExports.USER_POOL_ID,
          userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
        },
        
      });
  } 
  
  export const getAuthentication=async (jsonData)=>{
      configureCognito()
      const resp=await Auth.signIn(jsonData.email, jsonData.password);
      globalUser=resp      
      return resp
  }

  export const getGlobalUser=()=>{
    return globalUser
  }
  export const isValidSession=async()=>{
    try{
    const status=(await Auth.currentSession()).isValid()
    if(status==true)
     return true 
    }catch(e){
      return false


    }

    return false
   
  }

  export const isValidToken=async ()=>{
  const Session=await Auth.currentSession()  
  const currentDate=new Date()
  const currentTime=currentDate.getTime()/1000
  const expiryTime=Session.getIdToken().getExpiration()
  if((currentTime-expiryTime)>0) {
    return false
  }
  return true
 
  }

  export const getProfile=async ()=>{
    const Session=await Auth.currentUserInfo()
    
    console.log("Current Session")  
    console.log(Session)
   
    return Session
   
    }

  export const logOut=async ()=>{
    try{
      await Auth.signOut()    

    }catch(r){

    }
    return true
   
  }
  export const IsLoggedIn=async()=>{
    return (await Auth.currentSession()).isValid()
  }

  export const changePassword=async (jsonData)=>{
    try{
        console.log(jsonData)

       const user=getGlobalUser()
       console.log("-------Global User------")
       console.log(user)
     
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        }
        const newPassword = jsonData.password
        const loggedInUser = await Auth.completeNewPassword(
          user, 
          newPassword,         
        )
        console.log("Loginned User=")
        console.log(loggedInUser)
       
        return loggedInUser

    } catch (error) {
       console.log(error)
        return error
    }  
   
  }

  export const forgotPassword= async (jsonData)=>{

    try{
      const resp=await Auth.forgotPassword(jsonData.email)
      console.log("hi")
      console.log(resp)
      return resp
    }catch(err){
    
    console.log("Exception")  
    console.log(err)
    
    }

  }

  export const forgotPasswordSubmit= async (jsonData)=>{
    try{
      console.log(jsonData)
      const resp=await Auth.forgotPasswordSubmit(jsonData.email,jsonData.resetcode,jsonData.password)     
      return resp
    }catch(err){
    console.log("Exception")  
    console.log(err)
    return err
    }
  }

  export const resetPassword= async (jsonData)=>{
    try{
      //console.log(await Auth.currentUserPoolUser())
      //const reSignIn=Auth.signIn(jsonData.email,jsonData.opassword)
      const resp=Auth.changePassword(await Auth.currentUserPoolUser(),jsonData.opassword,jsonData.password)
      return resp
      //console.log(reSignIn)
    }catch(err){
      
      console.log("change password Error")
      console.log(err)
    }
  }
  export const createItem = async (item) => {
    try{
      const awtToken= (await Auth.currentSession()).getAccessToken().getJwtToken() 
      Amplify.configure({
         API:{
         graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
         graphql_headers : async () =>({
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${awtToken}`, 
     
         })        
       }})    
     console.log(awtToken)
     const graphqlResponse = await API.graphql({
       query: item,
       authMode: 'AMAZON_COGNITO_USER_POOLS',
       variables: { limit: 10 }, 
       headers: {
         Authorization: awtToken,
       },
     });
 
     return graphqlResponse

    }catch(err){
      console.log(err)
    }
  

   
  };
  
  // Function to read all items
  export const getListItems = async (graphqml) => {

    try{
    
    const awtToken= (await Auth.currentSession()).getAccessToken().getJwtToken() 
    console.log(awtToken)

    Amplify.configure({
      API:{
      graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
      graphql_headers : async () =>({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${awtToken}`, 
  
      })        
    }})   
   
    const graphqlResponse =  await API.graphql({
      query: graphqml,
      authMode: 'AMAZON_COGNITO_USER_POOLS', // Use this if you're using Cognito   
      headers: {
        Authorization:awtToken,
      },
      });

      return graphqlResponse;
    }catch(err){
      return null

    }

      

   
  };
  
  // Function to update an item
  export const updateItem = (itemId, newItem) => {
    const index = data.findIndex((item) => item.id === itemId);
    if (index !== -1) {
      data[index] = { ...data[index], ...newItem };
    }
  };
  
  // Function to delete an item
  export const deleteItem = async(graphqml) => {


    const awtToken= (await Auth.currentSession()).getAccessToken().getJwtToken() 
    console.log(awtToken)

    Amplify.configure({
      API:{
      graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
      graphql_headers : async () =>({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${awtToken}`, 
  
      })        
    }})   
   
    const graphqlResponse =  await API.graphql({
      query: graphqml,
      authMode: 'AMAZON_COGNITO_USER_POOLS', // Use this if you're using Cognito
      variables: { limit: 10 }, // Your query variables
      headers: {
        Authorization:awtToken,
      },
      });

    

      return graphqlResponse;
  };
  