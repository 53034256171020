import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,


} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { Route, useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import { RowingSharp } from '@mui/icons-material';
import {  toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { Chip } from '@mui/material';
import dateFormat from 'dateformat';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import { Helmet } from "react-helmet-async";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
function createData(id,notification_id,title, link , description,  created_at,created_by) {
    return { id,notification_id,title, link , description, created_at,created_by };
}

function DataTable() {
const [records, setRecords] = useState([]);
const [pages,setPages]=useState(1)

const addRecord = (newRecord) => {
  console.log("Add New Rocrods")
  
  setRecords([...records, newRecord]);
  console.log(records)
};
const deleteRecord = async (index) => {
  const updatedRecords = [...records];
  updatedRecords.splice(index, 1);
  setRecords(updatedRecords);
};

/********End of Global Variable SEction ******* */

const navigate = useNavigate()
const [uiRows,setUiRows]=React.useState([])

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredData = uiRows.filter((row) => {
      return (
        row.title.toLowerCase().includes(keyword) ||
        row.description.toLowerCase().includes(keyword)
        
      );
    });
    setFilteredRows(filteredData);
  };

  const handleContextMenuOpen = (event, row) => {
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentToken,setCurrentToken]=useState('')
  const [nextToken,setNextToken]=useState('')
  const [previousToken,setPreviousToken]=useState('')
  const [requestFlag,setRequestFlag]=useState(false)
  const [isLocaldata,setIsLocaldata]=useState(false)
  //*******Added constants for pagination******** */

 
  //const [pagesToken,setPagesToken]=useState([])

  const pagesToken=[]
  
  
  /********Added for get data from serever */
  
  let acccessToken=null
  
  let R=0;
  
  async function fetchMyAPI() {
    setRequestFlag(true)
    const rows=[];
    const query = `
    query ListNotifications {
        listNotifications(limit: 50, nextToken: "") {
          data {
            created_at
            created_by
            description
            link
            notification_id
            title
            updated_at
            updated_by
          }
          nextToken
        }
      }
        `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse?.data?.listNotifications?.data?.map((item)=>{
    rows.push(
      createData( 
      i,
      item.notification_id,
      item.title,
      item.link,   
      item.description,
      item.created_at,
      item.created_by 
    
    ),
    )
   i++;
    
    })
    setCurrentToken('')  
    setPreviousToken('')
    setNextToken(graphqlResponse?.data?.listNotifications?.nextToken)      
    console.log("Current Tone::"+graphqlResponse?.data?.listNotifications?.nextToken)
    setUiRows(rows)
    setFilteredRows(rows)
    localStorage.setItem("Notification",JSON.stringify(rows))
   if(graphqlResponse?.data?.listNotifications?.nextToken!=null){
    const arr={pageNo:1,token:graphqlResponse?.data?.listNotifications?.nextToken}
    if(records.length==0){
      addRecord(arr);
      console.log("Records")
      console.log(records) 
      setPage(page+1)  
    }
    
   }
   setRequestFlag(false)
    
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse?.data?.listNotifications)
}

async function fetchDataWithToken(token,pageNo) {
  setPreviousToken(currentToken) 
  const rows=[]
  const query = `
  query ListNotifications {
      listNotifications(limit: 50, nextToken: \"${token}\") {
        data {
          created_at
          created_by
          description
          link
          notification_id
          title
          updated_at
          updated_by
        }
        nextToken
      }
    }
      `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse?.data?.listNotifications?.data?.map((item)=>{
  rows.push(
    createData( 
    i,
    item.notification_id,
    item.title,
    item.link,   
    item.description,
    item.created_at,
    item.created_by 
  
  ),
  )
 i++;
  
  })
  setCurrentToken(token)  
  setNextToken(graphqlResponse?.data?.listNotifications?.nextToken)     
  console.log("Current Tone::"+graphqlResponse?.data?.listNotifications?.nextToken)
   //alert(rows.length)
   if(rows.length==0)
   return

  setUiRows(rows)
  setFilteredRows(rows)
  setPage(pages+1)
  if(graphqlResponse.data.length==0){
    navigate('/list-notification')
  }
  if(graphqlResponse.data.listNotifications.nextToken!=null && rows.length!=0){
    if(records.length<pageNo){
      const arr={pageNo:(records.length+1),token:graphqlResponse.data.listNotifications.nextToken}
      if(records.length>0){
        addRecord(arr);
        console.log("Records")
        console.log(records) 
        setPage((records.length+1))  
      }
    }
   }
  
  if(graphqlResponse.data.listNotifications.nextToken!=null){
    const arr={pageNo:pages,token:graphqlResponse.data.listNotifications.nextToken}
    pagesToken.push(arr)

    //setPagesToken(arr)
    console.log('Tokens with Pages')
    console.log(pagesToken)
   }
  setRequestFlag(false)
  console.log("Data rows" + JSON.stringify(rows))
  console.log("Graph ql rESPONSE=")
  console.log(graphqlResponse.data.listNotifications)
}

  useEffect(()=>{
    if(R>0) {R++;return;}
   
    fetchMyAPI()
         
   
  
  },[])
  
  
  /***************End of the fetch data */

  const CustomHeaderCell = ({ column }) => {
    return (
      <div>
        <strong>{column.headerName}</strong> {/* You can format the header here */}
      </div>
    );
  };

  const columns = [
  
    { field: 'title', headerName: 'Title',minWidth: 150,sortable: false,disableColumnMenu: true ,flex: 1,headerClassName: 'super-app-theme--header',},
    { field: 'link', headerName: 'Link',flex: 1,minWidth: 40,sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header',  
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
        <a href={params.value} target='_blank'><LinkIcon /></a>
       
      </div>
    ),
    },
    { field: 'description', headerName: 'Description',minWidth: 250,sortable: false,disableColumnMenu: true ,flex: 1,flexWrap: 'wrap',includeOutliers: true,headerClassName: 'super-app-theme--header',
    includeHeaders: false,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
   },
    {field: 'created_at', headerName: 'Created Date',flex: 1,minWidth: 200,sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header',  
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
     
       {dateFormat(params.value,'dd-mm-yyyy h:m:s')}
      </div>
    ),
    },   
    { field: 'action', headerName: 'Action', minWidth: 0,sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header', 
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
        console.log("Notification ID=")
        console.log(params.row.notification_id)
        handleClickOpen(params.row.notification_id)
        //handleContextMenuOpen(e, params.row);
      };
  
      return (
        <>
         <Button onClick={onClick} className='bi-trash'><DeleteIcon sx={{color: 'grey'}} /></Button>
        </>
     
      );
    }
  
  },
  ];
  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()
  const handleClickOpen = (id) => {
    setDelItem(id)
    setOpen(true);
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){
      //alert(deleteItem)
      await deleteNotification(delItem)
     
    }else{
      setOpen(false)
    }
    //alert(confirm)
    ;
  };

const deleteNotification=async(id)=>{
 
  try{
    const mutation = `
    mutation DeleteNotification {
            deleteNotification(notification_id: \"${id}\", debugFunctions: "getItemUsingPK,deleteItem") {
                ... on DeletedNotification {
                __typename
                notification_id
                }
                ... on CustomError {
                error
                message
                code
                }
            }
            }   
        `;  

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse?.data?.deleteNotification.code)

if(graphqlResponse?.data?.deleteNotification?.code){
  toast.error(graphqlResponse.data.deleteNotification.code);
  setOpen(false)
 return 
}

setOpen(false)
let tmpRows=[]
for(let i=0;i<filteredRows.length;i++){
 if(filteredRows[i].notification_id==id){
 }else{
   tmpRows.push(filteredRows[i])
 }
}
setFilteredRows(tmpRows);
toast.success('Records Deleted Successfully !');
 }catch(err){
    console.log("err")
    console.log(err)
  }
   
}
const [page, setPage] = useState(1);
const [rowsPerPage, setRowsPerPage] = useState(2);

// Your data source (replace with your own data source)
const allData = [
  // ... your data
];

// Calculate the data for the current page
const startIndex = (page - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const data = filteredRows.slice(startIndex, endIndex);
  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>      
        <ListItem button>
          <ListItemText onClick={e=>handleClickOpen(selectedRow.notification_id)} primary={`Delete`} />
        </ListItem>
      </List>
    </Popover>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

//miui pagination handling
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2; 
  const data1 = filteredRows; 
  const pageCount = Math.ceil(data1.length / itemsPerPage);
  const startPage = (currentPage - 1) * itemsPerPage;
  const endPage = startPage + itemsPerPage;
  const currentPageData = data1.slice(startPage, endPage);


  const handlePageChange =async(event, page) => {
    //alert(page + ":" + records.length)   
    if(records.length>=1){     
      if(page===1){
        for(let i=0;i<records.length;i++){
          deleteRecord(records.length-i) 
        }
        //alert(records.length)        
        fetchMyAPI()        
      }else{
        //alert(records.length) 
        fetchDataWithToken(records[page-2].token,page)  
      }      
      setCurrentPage(page);
    }
  };


  return (

    <>
    <Helmet title="List Notification" />
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>        
       
    <Typography component="h1" variant="h5" align="center" gutterBottom>
            Notification
            </Typography> 
      </Breadcrumbs>
        <Divider my={6} />
    
  
    <div style={{background:'#FFF',padding:'10px',width:'100%', overflow:'hidden'}}>

        <Grid container spacing={12}>
            <Grid item >
                
            </Grid>
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >
          <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
            <Button mr={2} variant="contained" color="primary" onClick={e=>navigate('/create-notification')}>
              + Add
            </Button>
          </div>
        </Grid>
        </Grid> 

      
           
              
          <DataGrid
            paddingTop='10px'
            rows={filteredRows}
            columns={columns}
            fullWidth={true}
            autoHeight 
            sx={{
              height: 300,
              width: '100%',
              '& .super-app-theme--header': {
                backgroundColor: '#fff',
                fontSize:'15px',
                fontWeight:'bold'
              },
            }}
            pageSizeOptions={[5,10,15]}
            components={{
              Toolbar: () => null, 
              ColumnHeaderMenu: () => null, 
              ColumnsPanel: () => null, 
              Export: () => null,
              Sortable:()=>null,
              Pagination: ()=>  {

                return (
                  <div>            
                  {/* Pagination component */}
                  <Stack direction="row" justifyContent="center">
                    <Pagination
                      count={records.length+1}
                      page={currentPage}
                      onChange={handlePageChange}
                      boundaryCount={10} // Adjust as needed
                      showFirstButton
                      showLastButton
                      renderItem={(item) => (
                        <PaginationItem
                          component="div"
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                </div>  
                )
             },
             NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {requestFlag==true? <div style={{textAlign:'center'}}>
                      <img style={{width:'48px'}} src="/static/img/brands/loading.gif"/>          
                    </div>:'No Records Found'}
                
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Local filter returns no result
              </Stack>
            )        
            }}
            onCellClick={(params, event) => {
              
            }}
          />
            {contextMenu} 
        
          <Paper mt={2}>
          
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
       
     
    </div>
    </>
  );
}

export default DataTable;
