import React,{useEffect, useState} from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Box,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'; 
import { getListItems,createItem } from '../../utils/crud';

import Autocomplete from '@mui/material/Autocomplete';
import {BrowserView, MobileView,isMobile } from 'react-device-detect';


const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function EmptyCard() {
  const navigate = useNavigate();
  const location=useLocation();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '250',
      },
    },
  };
const [listRoles,setListRoles]=useState([])
const [connectedType,setConnectedType]=useState('');  
  useEffect(()=>{
    if(location?.state==null){
      navigate('/all-account')
        }
    console.log("Tenant name=")
    console.log(location?.state.data)
   
    fetchConnectedType(location?.state.data.tenant)  
    fetchAllTenant()
  },[])


/*****Get Enterprise Role***** */
  async function  fetchConnectedType(tenant) {
    const rows=[]
    const query = `
    query ListEnterpriseRoles {
      listEnterpriseRoles(tenant: \"${tenant}\") {
        created_at
        created_by
        deleted_at
        role_name
        tenant
        updated_at
        updated_by
      }
    }
        `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
/*graphqlResponse.data.listEnterpriseRoles.map((item)=>{
 rows.push({label:item.role_name,value:item.role_name})
    
})*/
for(let i=0;i<graphqlResponse.data.listEnterpriseRoles.length;i++){
  rows.push({label:graphqlResponse.data.listEnterpriseRoles[i].role_name,value:graphqlResponse.data.listEnterpriseRoles[i].role_name})
}
setListRoles(rows)
    
}

/*************End of Get Enterprise Role*************** */


//************Autocomplete Section */

const [allTenant,setAllTenant]=React.useState([])
const [enterpriselist,setEnterpriselist]=React.useState([])

async function  fetchAllTenant() {

  const rows_alltenant=[]
  const query = `
  query SearchEnterprise {
    searchEnterprises(limit: 10, searchStr: "", nextToken: "") {
      data {
        enterprise_name
          description
          created_at
          created_by
          deactivated
          deleted_at
          tenant
          address_info
          website_url
          updated_at
          updated_by
      }
      nextToken
    }
  }
  
  `;  

const graphqlResponse =  await getListItems(query)
console.log("*********All Tenant***************")
console.log(graphqlResponse)

if(graphqlResponse?.data?.searchEnterprises?.data.length>0){

  for(let i=0;i<graphqlResponse.data.searchEnterprises.data.length;i++){   
  
    rows_alltenant.push({label:graphqlResponse.data.searchEnterprises.data[i].enterprise_name,value:graphqlResponse.data.searchEnterprises.data[i].tenant})
   
  }
  setOptions(rows_alltenant);
}


}

const [options, setOptions] = useState([{lable:'Select Enterprise',value:'Enterprise'}]);
const [inputValue, setInputValue] = useState();
const [loading, setLoading] = useState(false);

useEffect(() => {
  if (inputValue === '') {
    return;
  }

  //alert(inputValue)
  console.log(inputValue)
  setLoading(true);
  const fetchGraphQLData  = async () => {
  const rows=[]

  try{
    const query = `
    query SearchEnterprise {
      searchEnterprises(limit: 10, searchStr: \"${inputValue}\", nextToken: "") {
        data {
          enterprise_name
            description
            created_at
            created_by
            deactivated
            deleted_at
            tenant
            address_info
            website_url
            updated_at
            updated_by
        }
        nextToken
      }
    }
  `;  
  
const graphqlResponse =  await getListItems(query)

if(graphqlResponse?.data?.searchEnterprises?.data.length>0){

  for(let i=0;i<graphqlResponse.data.searchEnterprises.data.length;i++){   
  
      rows.push({label:graphqlResponse.data.searchEnterprises.data[i].enterprise_name,value:graphqlResponse.data.searchEnterprises.data[i].tenant})
   
  }
}
setOptions(rows);
console.log("Search Ahead => ")
console.log(graphqlResponse.data.searchEnterprises)
} catch (error) {
  console.error('An error occurred:', error);
}finally{
  setLoading(false);
}
}

  fetchGraphQLData ()
 


}, [inputValue]);


const changeTenant=async (e,latestValue) =>{
if(connectedType.length==0){
  setIsSelectedType(true)
} else{
  setOtherTenant(latestValue?.value)
  await finalIntersection(latestValue?.value)
}

 
}
/*******Intersection ***** */
const [ttype,setTtype]=useState("")
const [allType,setAllType]=React.useState([])
const [otherTenant,setOtherTenant]=useState("")
const[isSelectedType,setIsSelectedType]=useState(false)

const handleConnectedType=async(e)=>{
  setIsSelectedType(false)
  setConnectedType(e.target.value);  
}
const finalIntersection=async(tenantTo)=>{

  

  ///alert(connectedType + ":" + tenantTo)
  const finalRows=[]
  const connected_types=await fetchListConnectedType(connectedType);
  const others_role=await fetchOtherRoles(tenantTo)
  console.log("fetch List Connected Type")
  console.log(connected_types)
  console.log("fetch Others Roles")
  console.log(others_role)
  for(let i=0;i<connected_types.length;i++){
    if(others_role.indexOf(connected_types[i])>=0){

      finalRows.push(connected_types[i])
    }
  }
  setAllType(finalRows)
  console.log("final Intersection")
  console.log(finalRows)
}

async function  fetchListConnectedType(connected_type) {
  const rows=[]
  const query = `
  query ListConnectionRoles {
    listConnectedRoles(type: \"${connected_type}\")
  }
      `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)

graphqlResponse?.data?.listConnectedRoles?.map((item)=>{
rows.push(item)
  
})

return rows;
  
}

async function  fetchOtherRoles(tenant) {

  const rows=[]
  const query = `
  query ListEnterpriseRoles {
    listEnterpriseRoles(tenant: \"${tenant}\") {
      created_at
      created_by
      deleted_at
      role_name
      tenant
      updated_at
      updated_by
    }
  }
      `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)

graphqlResponse?.data?.listEnterpriseRoles.map((item)=>{
rows.push(item.role_name)
  
})

return rows;
  
}
/******End of Intersection******* */

const defaultValue = 'option2';

  return (
    
    <Formik 
      initialValues={{
        connected_tenant:location?.state?.tenant,
        connected_type: '',
        tenant: '',
        type: '',
        submit: false,
      }}
     
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        
        try {
       
        const mutation=`mutation CreateEnterpriseConnection {
            createEnterpriseConnection(enterpriseConnection:  {connected_tenant:\"${location?.state?.data?.tenant.toString()}\", connected_role:\"${connectedType.toString()}\", tenant: \"${otherTenant.toString()}\",role: \"${ttype.toString()}\"}) {
              ... on EnterpriseConnection {
                deleted_at
                connected_tenant
                connected_type
                created_at
                created_by
                tenant
                type
                updated_at
                updated_by
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }
          `
          console.log(mutation)
      
          
          const graphqlResponse = await createItem(mutation)
          
          console.log("Graph ql rESPONSE=")
          console.log(graphqlResponse)

          if(graphqlResponse.data.createEnterpriseConnection.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse.data.createEnterpriseConnection.message});
          }else{

            if(location?.state?.breadcrumbs){
                    
              navigate(location?.state?.breadcrumbs.url,{state:{tenant:location?.state?.data}})
            }else{
              navigate("/list-enterpriseconnection",{state:{data:location?.state?.data}})
            }
            
          
            //navigate("/list-enterpriseconnection")
          }
            
          
         
         
        } catch (error) {
          console.log(error)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>        
        
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          
          <Typography variant="h6" ml={2} gutterBottom>
           Connected From
          </Typography>
         <Grid container={isMobile?false:true}  spacing={2} my={2}>
            <Grid item md={6}>             
              <TextField
                  type="text"
                  name="connected_tenant" 
                  label="Connected From"          
                  disabled
                  value={location.state.data.enterprise_name}
                  error={Boolean(touched.connected_tenant && errors.connected_tenant)}
                  fullWidth
                  helperText={touched.connected_tenant && errors.connected_tenant}
                  hidden="true"          
                  my={2}
                />
            </Grid>
            <Grid item md={6} my={2}>
            <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"> Role</InputLabel>
                    <Select
                      labelId="connectedType"
                      id="connectedType"                    
                      label="Role"
                      value={connectedType}
                      defaultValue={connectedType}
                      onChange={handleConnectedType}      
                      MenuProps={MenuProps}
                    >
                       {listRoles.map((item) => (
                        <MenuItem key={item.label} value={item.value}>
                          {item.value}
                         </MenuItem>
                     ))}
                    </Select>
                    {isSelectedType==true? <div style={{color:'red'}}>Please Select Connected Type</div>:''}
                   
                  </FormControl>

            {/*<Select
                     
                     labelId=""
                     id="connectedType"                    
                     label="Connected Type"
                     defaultValue={connectedType}
                     value={connectedType}
                     fullWidth
                     onChange={e=>{
                       setConnectedType(e.target.value);                        
                     }}                      
                     MenuProps={MenuProps}
                   >
                    {listRoles.map((item) => (
                        <MenuItem key={item.label} value={item.value}>
                          {item.value}
                         </MenuItem>
                     ))}
                    </Select>*/}
                 
            </Grid>
         </Grid>

         <Divider my={2} />
         <Typography variant="h6" ml={2} gutterBottom>
           Connected To
          </Typography>
         <Grid container={isMobile?false:true} spacing={2} my={2} >
          
            <Grid item md={6}> 
            <Autocomplete
                          id="server-data-autocomplete"
                          options={options}
                          loading={loading}
                          onChange={changeTenant} 
                          onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
                          getOptionLabel={(option) => option.label} // Replace with your data's label property
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Connected To"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? 'Loading...' : null}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                 
            </Grid>

            <Grid item md={6} my={isMobile?4:0}>    
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"> Role</InputLabel>
                    <Select
                      labelId="ttype"
                      id="ttype"                    
                      label="Role"
                      onChange={(e)=>setTtype(e.target.value)}      
                      MenuProps={MenuProps}
                    >
                     {allType.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                    </Select>
                  </FormControl>
                  
            </Grid>
         </Grid>

         <div style={{marginTop:'20px'}}>
          <Button type="submit" fullWidth={isMobile?true:false} disabled={isSubmitting} variant="contained" color="primary">
            Create Connection
          </Button>

        
          </div>
          
        </form>
      )}
    </Formik>
  );
}


function CreateEnterpriseConnection() {

  const location=useLocation()
  const navigate = useNavigate();
 
  useEffect(()=>{
    if(location.state==null)
    navigate("/all-account")


console.log(location?.state?.tenant)

  },[])
  return (
    <React.Fragment>
      <Helmet title="Create Enterprise Connection" />
      

       <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Link component={NavLink} to="/all-account">
      <Button onClick={e=>navigate("/all-account")}>
      All Acounts
         </Button>
      </Link>
        <Link to="#">
         <Button onClick={(e)=>{
          if(location?.state?.breadcrumbs){
                    
            navigate(location?.state?.breadcrumbs.url,{state:{tenant:location?.state?.data}})
          }else{
            navigate("/list-enterpriseconnection",{state:{data:location?.state?.data}})
          }
          
        }
         }
         >
        {location?.state?.breadcrumbs?.text?location?.state?.breadcrumbs?.text:'List Enterprise Connection'}
        
         </Button>
          
         
         
        </Link>       
        <Typography> Create Enterprise Connection</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
        
            <EmptyCard />
            
         
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateEnterpriseConnection;
