import React from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { createItem} from '../../utils/crud';
import {BrowserView, MobileView,isMobile } from 'react-device-detect';
const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function EmptyCard() {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);


  return (
    <Formik 
      initialValues={{
        title:'',
        link: '',
        description: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required("Title is required"),
        link: Yup.string().max(255).required("Link is required").url('Not a value Url !'),
        description:Yup.string().max(255).required("Description is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        
        try {
          
          let cSession=await Auth.currentSession()
          let acccessToken= cSession.getAccessToken()
          let awtToken= acccessToken.getJwtToken()

          Amplify.configure({
            API:{
            graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
            graphql_headers : async () =>({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${awtToken}`, 
        
            })        
          }})       
          const tenant=values.identity + ".thecouponbureau.org";

          const mutation=`mutation CreateNotification { 
            createNotification(notification: {description:\"${values.description.toString()}\", link:\"${values.link.toString()}\", title: \"${values.title.toString()}\"}) {
              ... on Notification {
                description
                link
                created_at
                created_by
                notification_id
                title
                updated_at
                updated_by
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }
          `
          
          const graphqlResponse = await createItem(mutation)
          
          console.log("Graph ql rESPONSE=")
          console.log(graphqlResponse)

          if(graphqlResponse.data.createNotification.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse.data.createNotification.message});
          }else(
            navigate("/list-notification")
          )
         
         
        } catch (error) {
          console.log(error)
      

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
         
        <Card mb={6}>
        <CardContent>
        <Typography variant="h6" gutterBottom>
            Notification info
          </Typography>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}>
            <TextField
              type="text"
              name="title"
              label="Title"
              value={values.title}
              error={Boolean(touched.title && errors.title)}
              fullWidth
              helperText={touched.title && errors.title}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
                     
          </Grid>

          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}> 
            <TextField
            type="text"
            name="link"
            label="Link"
            value={values.link}
            error={Boolean(touched.link && errors.link)}
            fullWidth
            helperText={touched.link && errors.link}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>
          </Grid>

          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}> 
            <TextField
            type="text"
            name="description"
            label="Description"
            value={values.description}
            error={Boolean(touched.description && errors.description)}
            fullWidth
            helperText={touched.description && errors.description}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>
          </Grid>
          <Button type="submit" disabled={isSubmitting} fullWidth={isMobile?true:false} variant="contained" color="primary">
            Create Notifications
          </Button>
         
  </CardContent>  
  </Card>        
          
        </form>
      )}
    </Formik>
  );
}


function CreateNotification() {
  return (
    <React.Fragment>
      <Helmet title="Create Notification" />
      <Typography variant="h3" gutterBottom display="inline">
        Notifications
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>      
        <Link component={NavLink} to="/list-notification">
          List
        </Link>
        <Typography>Create Notifications</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateNotification;
