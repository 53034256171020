import React,{useState} from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import Resizer from "react-image-file-resizer";
import defaultImg from "./logo_icon.png"

const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
  
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function EmptyCard() {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);
  const [newImage,setNewImage]=useState(null);
  const [logoUrl,setLogoUrl]=useState('')

  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  

  const uploadIcon=async(_iconBase64)=>{

    let cSession=await Auth.currentSession()
    let acccessToken= cSession.getAccessToken()
    let awtToken= acccessToken.getJwtToken()
  
    Amplify.configure({
      API:{
      graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
      graphql_headers : async () =>({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${awtToken}`, 
  
      })        
    }}) 
//let assetName=document.getElementById("asset_name").value
console.log("Upload Section=")
let assetName=Math.ceil(Math.random()*999999).toString().padEnd(6,"0")
console.log(assetName)

let finalBase64=_iconBase64.split(',')[1];

const mutation=`mutation UploadAsset {
  uploadAsset(input: {asset_name: \"${assetName}\", base64_data: \"${finalBase64} \"

, content_type: "image/jpg", extension: ".jpg"}) {
    ... on Asset {
      __typename
      asset_id
      asset_name
      created_at
      created_by
      public_url
      tenant
      updated_at
      updated_by
    }
    ... on CustomError {
      error
      message
      code
    }
  }
}`

console.log(finalBase64);


const graphqlResponse = await API.graphql({
  query: mutation,
  authMode: 'AMAZON_COGNITO_USER_POOLS', // Use this if you're using Cognito
  variables: { limit: 10 }, // Your query variables
  headers: {
    Authorization: awtToken,
  },
});

  console.log("Graph ql rESPONSE=")
  console.log(graphqlResponse.data.uploadAsset.public_url)
  setLogoUrl(graphqlResponse.data.uploadAsset.public_url)
	}
  const fileChangedHandler=(event)=>{
   
		var fileInput = false;
		if (event.target.files[0]) {
		  fileInput = true;
		}
		if (fileInput) {
		  try {
			Resizer.imageFileResizer(
			  event.target.files[0],
			  160,
			  160,
			  "JPEG",
			  100,
			  0,
			  (uri) => {
				setNewImage(uri);
				uploadIcon(uri);
			  },
			  "base64",
			  96,
			  96
			);
		  } catch (err) {
			console.log(err);
		  }
		}
	}

  return (
    <Formik 
      initialValues={{
       
        store_locator_url:'',
        retailer: '',
        description: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        retailer: Yup.string().max(255).required("Retailer is required"),
        store_locator_url: Yup.string().max(255).required("Location URL is required"),
        description:Yup.string().max(255).required("Description is required"),
       
      })}

     
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
      
        try {
          
          let cSession=await Auth.currentSession()
          let acccessToken= cSession.getAccessToken()
          let awtToken= acccessToken.getJwtToken()

          Amplify.configure({
            API:{
            graphql_endpoint:awsExports.AWS_APPSYNC_GRAPHQULENDPOINT,
            graphql_headers : async () =>({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${awtToken}`, 
        
            })        
          }}) 
          
          if(logoUrl.length==0){
            setStatus({ success: false });
            setErrors({ submit:  'Please Upload Logo !'})
            return
          }else{

          const mutation=`mutation CreateLiveRetailer {
            createLiveRetailer(liveRetailer: {description:\"${values.description.toString()}\",retailer_logo:\"${logoUrl.toString()}\", retailer_name:\"${values.retailer.toString()}\", store_locator_url: \"${values.store_locator_url.toString()}\"}) {
              ... on LiveRetailer {
                retailer_logo
                description
                created_at
                created_by
                retailer_id
                retailer_name
                store_locator_url
                updated_at
                updated_by
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }`

          console.log(mutation)
          
          const graphqlResponse = await API.graphql({
            query: mutation,
            authMode: 'AMAZON_COGNITO_USER_POOLS', // Use this if you're using Cognito
            variables: { limit: 10 }, // Your query variables
            headers: {
              Authorization: awtToken,
            },
          });
          
          console.log("Graph ql rESPONSE=")
          console.log(graphqlResponse)

          if(graphqlResponse.data.createLiveRetailer.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse.data.createLiveRetailer.message});
          }else(
            navigate("/live-retailers")
          )
          }


         
         
        } catch (error) {
          console.log(error)
          

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
          {/*<Alert mt={3} mb={3} severity="success">
            {localStorage.getItem("email")}
      </Alert>*/}

<Card mb={6}>
<CardContent>
  
          <Typography variant="h6"  gutterBottom>
            Retailer info
          </Typography>

          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container spacing={6}>
          <Grid item md={4} >
            
            <CenteredContent>
            <Grid item md={12}> 
           
            </Grid>
              <BigAvatar
                alt="Remy Sharp"
                src={newImage || defaultImg} 
              />
              {/*<img src={newImage || defaultImg} className='align-items-center' />*/}
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={fileChangedHandler}
              />
              <label htmlFor="raised-button-file">
                <Button  variant="contained" color="primary" component="span">
                  
                  <CloudUpload    mr={2} /> Select Logo
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </label>
            </CenteredContent>
          </Grid> 
          
          <Grid item md={8}>
            
            <TextField
              id="retailer"
              label="Retailer Name"
              name="retailer"
              value={values.retailer}
              error={Boolean(touched.retailer && errors.retailer)}
              fullWidth
              helperText={touched.retailer && errors.retailer}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />

           
              <TextField
                label="Description"
                name="description"
                id="description"
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
           

            <TextField
            type="text"
            name="store_locator_url"
            label="Store Locator URL"
            value={values.store_locator_url}
            error={Boolean(touched.store_locator_url && errors.store_locator_url)}
            fullWidth
            helperText={touched.store_locator_url && errors.store_locator_url}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <div style={{textAlign:'right'}}>
          <Button type="submit"  disabled={isSubmitting} variant="contained" color="primary">
            Create Retailer
          </Button>
          </div>
           
          </Grid>
            
          </Grid>

          <Grid container spacing={6}>
            <Grid item md={6}> 
            
            </Grid>
          </Grid>

         
         
  </CardContent>  
  </Card>        
          
        </form>
      )}
    </Formik>
  );
}


function CreateLiveRetailer() {
  return (
    <React.Fragment>
      <Helmet title="Create Live Retailer" />
      <Typography variant="h3" gutterBottom display="inline">
        Retailers
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
       
        <Link component={NavLink} to="/live-retailers">
          List
        </Link>
        <Typography>Create Live Retailer</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateLiveRetailer;
