import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import { API, graphqlOperation } from 'aws-amplify';
import { createItem } from '../../utils/crud';
import {isMobile } from 'react-device-detect';
const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function EmptyCard() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [personName, setPersonName] = React.useState([]);

  useEffect(()=>{
    console.log(location.state)

  },[])


  return (
    <Formik 
      initialValues={{
        email:'',
        tenant: location?.state?.tenant?.enterprise_name,       
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Not a valid format').max(255).required("Email is required"),
        tenant: Yup.string().max(255).required("Tenant is required"),        
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {        
        try {          
          const mutation=`mutation CreateEnterpriseUser {
            createEnterpriseUser(enterpriseUser: {email: \"${values.email}\", tenant: \"${location?.state?.tenant?.tenant}\"}, debugFunctions: "getItemUsingPK") {
              ... on EnterpriseUser {
                __typename
                tenant
                email
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }`
          
          const graphqlResponse = await createItem(mutation)
          console.log("Graph ql rESPONSE=")
          console.log(mutation)

          if(graphqlResponse.data.createEnterpriseUser.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse.data.createEnterpriseUser.message});
          }else{

            if(location?.state?.breadcrumbs){
                    
              navigate(location?.state?.breadcrumbs.url,{state:{tenant:location?.state?.tenant}})
            }else{
              navigate("/list-enterpriseuser",{state:{tenant:location.state.tenant}})
            }

           
          }
        
        } catch (error) {
          console.log(error)
          

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
        <Card mb={6}>
        <CardContent>
        <Typography variant="h6" gutterBottom>
            User info
          </Typography>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}>
            <TextField
              type="text"
              name="email"
              label="Email"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            </Grid>
                      
          </Grid>

          <Grid container={isMobile?false:true} spacing={6}>
            <Grid item md={6}> 
            <TextField
            type="text"
            name="tenant"
            label="Enterprise"
            disabled
            value={values.tenant}
            error={Boolean(touched.tenant && errors.tenant)}
            fullWidth
            helperText={touched.tenant && errors.tenant}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>
          </Grid>

         
          <Button type="submit" disabled={isSubmitting} fullWidth={isMobile?true:false} variant="contained" color="primary">
            Create User
          </Button>
         
  </CardContent>  
  </Card>        
          
        </form>
      )}
    </Formik>
  );
}
function CreateEnterpriseUser() {
  const location=useLocation()
  const navigate = useNavigate();
 
  useEffect(()=>{
    if(location.state==null)
    navigate("/all-account")


console.log(location?.state?.tenant)

  },[])
  return (
    <React.Fragment>
      <Helmet title="Create Enterprise User" />
      
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      <Link component={NavLink} to="/all-account">
      <Button onClick={e=>navigate("/all-account")}>
      All Acounts
         </Button>
      </Link>
        <Link to="#">
         <Button onClick={(e)=>{
          if(location?.state?.breadcrumbs){
                    
            navigate(location?.state?.breadcrumbs.url,{state:{tenant:location?.state?.tenant}})
          }else{
            navigate("/list-enterpriseuser",{state:{tenant:location?.state?.tenant}})
          }
          
        }
         }
         >
        {location?.state?.breadcrumbs?.text?location?.state?.breadcrumbs?.text:'List Enterprise Users'}
        
         </Button>
          
         
         
        </Link>       
        <Typography> Create Enterprise User</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateEnterpriseUser;
