import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  CardContent,
  Button as MuiButton,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Paper as MuiPaper,
  Select,
  Input,
  InputLabel,
  MenuItem,
  Checkbox,
  Chip,
  ListItemText,
  Alert as MuiAlert,
  Table,
  TableRow,
  TableCell,
  Switch,
  FormGroup,
  FormControlLabel,
  
} from "@mui/material";
import { spacing } from "@mui/system";

import { ContactSupportOutlined, LocationOff, CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from '../../components/auth/aws-exports';
import {BrowserView, MobileView,isMobile } from 'react-device-detect';
import {  toast } from 'react-toastify';
import { createItem,deleteItem } from '../../utils/crud';

const FormControl = styled(MuiFormControl)(spacing);

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const myDiv = styled.div`
display: flex;
flex-wrap: wrap;
flex: 0 0 calc(33.33% - 10px); /* Adjust the width to create three items in a row with some spacing */
margin: 5px;
padding: 10px;
background-color: #e0e0e0;
border: 1px solid #ccc;
text-align: center;

`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const names = [
  "Super Admin",
  "Admin",
  "Operator",
  "Users",
  
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EmptyCard() {
  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID      
    },
    
  });

  const navigate = useNavigate();
  const location=useLocation()
  const [personName, setPersonName] = React.useState([]);
  const [isLocation,setIsLocation]=React.useState(false)
  const [isActive,setIsActive]=React.useState(true)

  useEffect(()=>{
    console.log(location.state.data)
   
    if(location.state==null){
      //console.log("aaya nul")
      setIsLocation(false)
    }else{
      setIsLocation(true)
      console.log(location.state.data.enterprise_name)
    }

  },[])
  const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const handleChanges = async (event) => {
  //alert(event.target.checked)
  if(event.target.checked==true){
  setIsActive(true)
  }else{
    setIsActive(false)
  }
}

  

  return (
    <Formik 
      initialValues={{
        identity: location?.state?.data?.tenant,
        name: location?.state?.data?.enterprise_name,
        description: location?.state?.data?.description,
        address:location?.state?.data?.address_info,
        web_url:location?.state?.data?.website_url,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        identity: Yup.string().max(255).required("Identity is required"),
        name: Yup.string().max(255).required("Name is required"),
        description:Yup.string().max(255).required("Description is required"),
        address:Yup.string().max(255).required("Address is required"),
        web_url:Yup.string().max(255).required("Website Url is required").url("Not valid url"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        
        try {          
              
          //const tenant=values.identity + ".thecouponbureau.org";

          const mutation=`mutation MyMutation {
            updateEnterprise(tenant: \"${location?.state?.data?.tenant.toString()}\", enterprise: {deactivated: ${isActive},description:\"${values.description.toString()}\", enterprise_name:\"${values.name.toString()}\",address_info:\"${values.address.toString()}\",website_url:\"${values.web_url.toString()}\"}) {
              ... on Enterprise {
                deleted_at
                deactivated
                description
                enterprise_name
                tenant
                updated_at
                updated_by
                created_at
                created_by
              }
              ... on CustomError {
                error
                message
                code
              }
            }
          }
          `
          console.log(mutation)

             
          const graphqlResponse=await createItem(mutation)          
          console.log("Graph ql rESPONSE=")
          console.log(graphqlResponse)

          if(graphqlResponse.data.updateEnterprise.error==true){
          setStatus({ success: false });
          setErrors({ submit:  graphqlResponse.data.updateEnterprise.message});        

          }else(
            navigate("/all-account")
          )
         
         
        } catch (error) {
          console.log(error)      
          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
          {/*<Alert mt={3} mb={3} severity="success">
            {localStorage.getItem("email")}
      </Alert>*/}

          <Card>
          <CardContent>
          <Typography variant="h6" gutterBottom>
            Account info
          </Typography>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <div>
            
          </div>
          
          <Grid container={isMobile?false:true} spacing={2}>
            <Grid item md={isMobile?12:6}>          
            <TextField
              type="text"
              name="identity"
              label="Identity"
              value={values.identity}
              disabled
              error={Boolean(touched.identity && errors.identity)}
              fullWidth
              helperText={touched.identity && errors.identity}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />           
            </Grid>
           
                     
          </Grid>


          <Grid  spacing={2} >
            <Grid item md={isMobile?12:6}> 
            <TextField
            type="text"
            name="name"
            label="Name"
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>  
          </Grid>

          <Grid  spacing={12}>
            <Grid item md={isMobile?12:6} > 
            <TextField
            type="text"
            name="description"
            label="Description"
            value={values.description}
            error={Boolean(touched.description && errors.description)}
            fullWidth
            helperText={touched.description && errors.description}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>
          </Grid>
          <Grid  spacing={6}>
            <Grid item md={isMobile?12:6}> 
            <TextField
            type="text"
            name="address"
            label="Address"
            value={values.address}
            error={Boolean(touched.address && errors.address)}
            fullWidth
            helperText={touched.address && errors.address}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>
          </Grid>
          <Grid  spacing={6}>
            <Grid item md={isMobile?12:6}> 
            <TextField
            type="text"
            name="web_url"
            label="Website Url"
            value={values.web_url}
            error={Boolean(touched.web_url && errors.web_url)}
            fullWidth
            helperText={touched.web_url && errors.web_url}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
            </Grid>
          </Grid>
          <Grid  spacing={6}>
          <Grid item md={isMobile?12:6}> 
          <FormControl component="fieldset" variant="standard">
          <FormGroup>
          <FormControlLabel
           control={
          <Switch
            defaultChecked={isActive}                                    
            onChange={handleChanges}            
            name='activate'
          />
                   
           }
                   label='Activte/Deactivate'
                /> 
                 
            </FormGroup>
          </FormControl>
          </Grid>
          </Grid>

          <Button type="submit" disabled={isSubmitting} fullWidth={isMobile?true:false} variant="contained" color="primary">
            Update Enterprise
          </Button>
         
  </CardContent>  
  </Card>        
          
        </form>
      )}
    </Formik>
  );
}


function CreateAccount() {
  const location =useLocation()
  const navigate=useNavigate()
  return (
    <React.Fragment>
      <Helmet title="Update Account" />
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>       
        <Link >
        <Button onClick={e=>navigate("/view-enterprisedetails",{state:{tenant:location.state.data}})}>
        View Enterprise Details
        </Button>
        
        </Link>
        <Typography>Update Account</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>

    
    </React.Fragment>
  );
}

export default CreateAccount;
