import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { Route, useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import { RowingSharp } from '@mui/icons-material';
import {  toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Pagination, PaginationItem, Stack } from '@mui/material';
import {BrowserView, MobileView,isMobile } from 'react-device-detect';
import { Helmet } from "react-helmet-async";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
function createData(id,retailer_logo, retailer_name,store_locator_url , description,retailer_id, deactivated , created_at,created_by,total_live_stores) {
  return { id,retailer_logo, retailer_name,store_locator_url , description, deactivated,retailer_id, created_at,created_by,total_live_stores };
}

function DataTable() {
  const [records, setRecords] = useState([]);
  const [pages,setPages]=useState(1)
  
  const addRecord = (newRecord) => {
    console.log("Add New Rocrods")
    
    setRecords([...records, newRecord]);
    console.log(records)
  };
  const deleteRecord = async (index) => {
    const updatedRecords = [...records];
    updatedRecords.splice(index, 1);
    setRecords(updatedRecords);
  };
  
  /********End of Global Variable SEction ******* */



const navigate = useNavigate()
const [uiRows,setUiRows]=React.useState([])

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredData = uiRows.filter((row) => {
      return (
        row.title.toLowerCase().includes(keyword) ||
        row.description.toLowerCase().includes(keyword)
        
      );
    });
    setFilteredRows(filteredData);
  };

  const handleContextMenuOpen = (event, row) => {
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentToken,setCurrentToken]=useState('')
  const [nextToken,setNextToken]=useState('')
  const [previousToken,setPreviousToken]=useState('')
  const [requestFlag,setRequestFlag]=useState(false)
  /********Added for get data from serever */
  
  let acccessToken=null
  
  let R=0;
  
  async function fetchMyAPI() {
    const rows=[];
    setRequestFlag(true)
    const query = `
    query ListLiveRetailers {
      listLiveRetailers(limit: 2, nextToken: "") {
        data {
          created_at
          created_by
          description
          retailer_id
          retailer_logo
          retailer_name
          store_locator_url
          updated_at
          updated_by
          total_live_stores
        }
        nextToken
      }
    }
        `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse.data.listLiveRetailers.data.map((item)=>{
    rows.push(
      createData( 
        i,    
        item.retailer_logo,
        item.retailer_name,
        item.store_locator_url,
        item.description,
        item.retailer_id,   
        item.deactivated,
        item.created_at,
        item.created_by,
        item.total_live_stores 
    
    ),
    )
   i++;
    
    })

    
    setCurrentToken('')  
    setPreviousToken('')
    setNextToken(graphqlResponse.data.listLiveRetailers.nextToken)      
    console.log("Current Tone::"+graphqlResponse.data.listLiveRetailers.nextToken)
    setUiRows(rows)
    setFilteredRows(rows)
    setRequestFlag(false)
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse.data.listLiveRetailers)


    if(graphqlResponse.data.listLiveRetailers.nextToken!=null){
      const arr={pageNo:1,token:graphqlResponse.data.listLiveRetailers.nextToken}
      if(records.length==0){
        addRecord(arr);
        console.log("Records")
        console.log(records) 
        setPage(page+1)  
      }
    }
}

/***********Calling with Token*********** */
async function fetchDataWithToken(token,pageNo) {
  setPreviousToken(currentToken) 
  const rows=[];
    setRequestFlag(true)
    const query = `
    query ListLiveRetailers {
      listLiveRetailers(limit: 50, nextToken: \"${token}\") {
        data {
          created_at
          created_by
          description
          retailer_id
          retailer_logo
          retailer_name
          store_locator_url
          updated_at
          updated_by
          total_live_stores
        }
        nextToken
      }
    }
        `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let i=1
graphqlResponse.data.listLiveRetailers.data.map((item)=>{
    rows.push(
      createData( 
        i,    
        item.retailer_logo,
        item.retailer_name,
        item.store_locator_url,
        item.description,
        item.retailer_id,   
        item.deactivated,
        item.created_at,
        item.created_by,
        item.total_live_stores 
    
    ),
    )
   i++;
    
    })

    
    setCurrentToken('')  
    setPreviousToken('')
    setNextToken(graphqlResponse?.data?.listLiveRetailers?.nextToken)      
    console.log("Current Tone::"+graphqlResponse?.data?.listLiveRetailers?.nextToken)
    setUiRows(rows)
    setFilteredRows(rows)
    setRequestFlag(false)
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse?.data?.listLiveRetailers)

    if(graphqlResponse.data.length==0){
      navigate('/list-notification')
    }
    if(graphqlResponse?.data?.listLiveRetailers?.nextToken!=null && rows.length!=0){
      if(records.length<pageNo){
        const arr={pageNo:(records.length+1),token:graphqlResponse?.data?.listLiveRetailers?.nextToken}
        if(records.length>0){
          addRecord(arr);
          console.log("Records")
          console.log(records) 
          setPage((records.length+1))  
        }
      }
     }


}

  useEffect(()=>{
    if(R>0) {R++;return;}       
    fetchMyAPI()
  
  },[])
  
  
  /***************End of the fetch data */

  const columns = [
    { field: 'retailer_logo', headerName: 'Retailer Logo',sortable: false, minWidth: 100, flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',disableColumnMenu: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        <img  src={params.value} style={{borderRadius: '50%',height:'36px',width:'36px'}}/>
        
      </div>
    )
    },   
    
    { field: 'retailer_name', headerName: 'Retailer Name',minWidth: 150,sortable: false ,flex: 1,headerClassName: 'super-app-theme--header',disableColumnMenu: true},
    
    { field: 'store_locator_url', headerName: 'Store Locator URL',minWidth: 80,sortable: false ,flex: 1,headerClassName: 'super-app-theme--header',disableColumnMenu: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word',overflow:'inherit'}}>
        <a href={params.value} target='_blank'><LinkIcon /></a>
      </div>
    ),
    }, 
    { field: 'total_live_stores', headerName: 'Total Stores',sortable: false,flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',disableColumnMenu: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },  
    
    { field: 'description', headerName: 'Description',sortable: false,flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',disableColumnMenu: true,
    renderCell: (params) => (
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {params.value}
      </div>
    ),
  },   
    { field: 'action', headerName: 'Action', minWidth: 100,sortable: false,headerClassName: 'super-app-theme--header',disableColumnMenu: true,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
        console.log(params.retailer_id)
        handleContextMenuOpen(e, params.row);
      };
  
      return (
        <>
         <Button onClick={onClick}><MoreVertIcon/></Button>
         
        </>
     
      );
    }
  
  },
  ];
  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()
  const handleClickOpen = (id,otype) => {
    setContextMenuAnchor(null);
    setDelItem(id.retailer_id)  
  
    if(otype==='Live Store'){     
      navigate('/live-stores',{state:{retailer_id:id.retailer_id,retailer_name:id.retailer_name}})
    }       
    else{
      setOpen(true);
    }   
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){
      //alert(deleteItem)
      await deleteNotification(delItem)
     
    }else{
      setOpen(false)
    }
    //alert(confirm)
    ;
  };

const deleteNotification=async(id)=>{
 
  try{
      const mutation = `
      mutation DeleteLiveRetailer {
        deleteLiveRetailer(retailer_id: \"${id}\") {
          ... on DeletedLiveRetailer {
            __typename
            retailer_id
          }
          ... on CustomError {
            error
            message
            code
          }
        }
      }   
          `;  

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse?.data?.deleteLiveRetailer.code)

if(graphqlResponse?.data?.deleteLiveRetailer?.code){
  toast.error(graphqlResponse.data.deleteLiveRetailer.code);
  setOpen(false)
 return 
}

setOpen(false)
let tmpRows=[]
for(let i=0;i<filteredRows.length;i++){
 if(filteredRows[i].retailer_id==id){
 }else{
   tmpRows.push(filteredRows[i])
 }
}
setFilteredRows(tmpRows);
toast.success('Records Deleted Successfully !');


  }catch(err){

    console.log("err")
    console.log(err)

  }
   
}
const [page, setPage] = useState(1);
const [rowsPerPage, setRowsPerPage] = useState(2);

// Your data source (replace with your own data source)
const allData = [
  // ... your data
];

// Calculate the data for the current page
const startIndex = (page - 1) * rowsPerPage;
const endIndex = startIndex + rowsPerPage;
const data = filteredRows.slice(startIndex, endIndex);
  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
      <ListItem button>         
           <ListItemText onClick={e=>handleClickOpen(selectedRow,'Live Store')} primary={`Live Store`} />
        </ListItem>      
        <ListItem button>         
           <ListItemText onClick={e=>handleClickOpen(selectedRow,'Delete')} primary={`Delete`} />
        </ListItem>
      </List>
    </Popover>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

//miui pagination handling
const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 2; 
const data1 = filteredRows; 
const pageCount = Math.ceil(data1.length / itemsPerPage);
const startPage = (currentPage - 1) * itemsPerPage;
const endPage = startPage + itemsPerPage;
const currentPageData = data1.slice(startPage, endPage);


const handlePageChange =async(event, page) => {
  //alert(page + ":" + records.length)   
  if(records.length>=1){     
    if(page===1){
      for(let i=0;i<records.length;i++){
        deleteRecord(records.length-i) 
      }
      //alert(records.length)        
      fetchMyAPI()        
    }else{
      //alert(records.length) 
      fetchDataWithToken(records[page-2].token,page)  
    }      
    setCurrentPage(page);
  }
};

  return (
<>
    <Helmet title="List Live Retailer" />
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
    <Typography component="h1" variant="h5" align="center" gutterBottom>
    Live Retailer
    </Typography> 
    </Breadcrumbs>
    <Divider my={6} />


    <div style={{ width: '100%' }}>

      <Card mb={6}>
        <CardContent>
        <Grid container spacing={12}>
        <Grid item >
        
      
        </Grid>
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >
       
        <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
        <Button mr={2} variant="contained" style={{padding:isMobile?'8px':''}} color="primary" onClick={e=>navigate('/create-liveretailer')}>
          + Add
        </Button>
        </div>
        </Grid>
        </Grid>
    
      <DataGrid
        paddingTop='10px'
        rows={filteredRows}
        columns={columns}
        fullWidth={true}
        autoHeight 
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#fff',
            fontSize:'15px',
            fontWeight:'bold'
            
          },
        }} 
        pageSizeOptions={[5,10,15]}
        components={{
          Toolbar: () => null, 
          ColumnHeaderMenu: () => null, 
          ColumnsPanel: () => null, 
          Export: () => null,
          Pagination: ()=>  {
          return (
            <div>            
            {/* Pagination component */}
            <Stack direction="row" justifyContent="center">
              <Pagination
                count={records.length+1}
                page={currentPage}
                onChange={handlePageChange}
                boundaryCount={10} // Adjust as needed
                showFirstButton
                showLastButton
                renderItem={(item) => (
                  <PaginationItem
                    component="div"
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>         
          )},
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {requestFlag==true? <div style={{textAlign:'center'}}>
                    <img style={{width:'48px'}} src="/static/img/brands/loading.gif"/>          
                  </div>:'No Records Found'}
              
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>
          )

        }}
        onCellClick={(params, event) => {
          
        }}
      />
      {contextMenu}     
         
          <Paper mt={0}>
          
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </CardContent>
      </Card> 
    </div>
    </>
  );
}

export default DataTable;
