import * as React from 'react';
import {useState,useEffect} from 'react';
import styled from "@emotion/styled";
import { DataGrid, GridToolbar, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import { TextField, InputAdornment, Button, Popover, List, ListItem, ListItemText,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  Typography,
  Paper as MuiPaper,
  Alert as MuiAlert,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Input,
  Tooltip,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getListItems,deleteItem } from '../../utils/crud';
import { useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {  toast } from 'react-toastify';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { Typeahead } from 'react-bootstrap-typeahead';
import { SignalWifiStatusbarNullOutlined } from '@mui/icons-material';
import {BrowserView, MobileView,isMobile} from 'react-device-detect';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Chip from '@mui/material/Chip';
import { Helmet } from "react-helmet-async";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);


function createData(id,enterprise_name, tenant,description ,address_info,website_url,created_at,updated_at) {
    return { id,enterprise_name, tenant,description,address_info,website_url, created_at,updated_at };
}

function DataTable() {
  const [records, setRecords] = useState([]);
  const [pages,setPages]=useState(1)
  
  const addRecord = (newRecord) => {
    console.log("Add New Rocrods")
    
    setRecords([...records, newRecord]);
    console.log(records)
  };
  const deleteRecord = async (index) => {
    const updatedRecords = [...records];
    updatedRecords.splice(index, 1);
    setRecords(updatedRecords);
  };
  const [page, setPage] = useState(1);

  
  /********End of Global Variable SEction ******* */


const navigate = useNavigate()
const [uiRows,setUiRows]=React.useState([])
const rows=[]
const [filteredRows, setFilteredRows] = React.useState([]);
const [contextMenuAnchor, setContextMenuAnchor] = React.useState(null);
const [selectedRow, setSelectedRow] = React.useState(null);

  

  const handleContextMenuOpen = (event, row) => {
    setContextMenuAnchor(event.currentTarget);
    setSelectedRow(row);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);  
  const [requestFlag,setRequestFlag]=useState(false)
  const [limits,setlimits]=useState(false)
  const [isClicked,setIsClicked]=useState(false)
  const [currentToken,setCurrentToken]=useState('')
  const [nextToken,setNextToken]=useState('')
  const [previousToken,setPreviousToken]=useState('')
  /********Added for get data from serever */ 
  let R=0;  
  async function fetchMyAPI(limit,token,pageNo) {
    const rows=[]       
    setRequestFlag(true)
    const query = `
    query MyQuery {
      listEnterprises(limit: ${limit}, nextToken: \"${token}\",sortField: "enterprise_name", sortOrder: "asc") {
        data {
          enterprise_name
          description
          created_at
          created_by
          deactivated
          deleted_at
          tenant
          address_info
          website_url
          updated_at
          updated_by
        },
        nextToken 
      }         
    }
  `;  

const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let urRows=graphqlResponse.data.listEnterprises.data;
for(let j=0;j<urRows.length;j++){
  let item=urRows[j]; 
  rows.push(
    createData( 
    j,
    item.enterprise_name,   
    item.tenant,
    item.description,       
    item.address_info,
    item.website_url,   
    item.created_at,
    item.updated_at
  ),
  )
}   
    setRequestFlag(false)
    setFilteredRows(rows)
   
    //setUiRows(rows)    
    console.log("Data rows" + JSON.stringify(rows))
    console.log("Graph ql rESPONSE=")
    console.log(graphqlResponse.data.listEnterprises)
    if(graphqlResponse.data.listEnterprises.nextToken!=null){
      setCurrentToken('')  
      setPreviousToken('')
      setNextToken(graphqlResponse.data.listEnterprises.nextToken) 
      setlimits(true)     
      if(pageNo==1){   
          if(records.length==0){
            const arr={pageNo:page,token:graphqlResponse.data.listEnterprises.nextToken}
            addRecord(arr);
            console.log("Records")
            console.log(records) 
            setPage((records.length+1))
          }  
          
      }else
      if(records.length<pageNo){       
        const arr={pageNo:(records.length+1),token:graphqlResponse.data.listEnterprises.nextToken}
        if(records.length>0){
          addRecord(arr);
          console.log("Records")
          console.log(records) 
          setPage((records.length+1))  
        }
      }

      console.log("Records=")
      console.log(records)

      
     }else{
      //alert(pageNo)
      //if(page==1)
      //setlimits(false)
     }
    
}
async function fetchByRole(Role) {
  const rows=[] 
  setRequestFlag(true)
  const query = `
  query MyQuery {
    listEnterprises(limit: 10, nextToken: "", role_name: \"${Role}\",sortField: "enterprise_name", sortOrder: "asc", debugFunctions: "getItemsPaginatedSortByCreatedAtOnIndex") {
      data {
        enterprise_name
          tenant
          address_info
          website_url
          created_at
          updated_at
      }
    }
  }
`;  
console.log(query)
const graphqlResponse =  await getListItems(query)
console.log(graphqlResponse)
let urRows=graphqlResponse.data.listEnterprises.data;
for(let j=0;j<urRows.length;j++){
let item=urRows[j]; 
rows.push(
  createData( 
  j,
  item.enterprise_name,   
  item.tenant,
  item.description,       
  item.address_info,
  item.website_url,   
  item.created_at,
  item.updated_at
),
)
}   
  setRequestFlag(false)
  setFilteredRows(rows)
  //setUiRows(rows)    
  console.log("Data rows" + JSON.stringify(rows))
  console.log("Graph ql Role base search rESPONSE=")
  console.log(graphqlResponse.data.listEnterprises)

  if(graphqlResponse.data.listEnterprises.nextToken!=null){
    const arr={pageNo:1,token:graphqlResponse.data.listEnterprises.nextToken}
    if(records.length==0){
      addRecord(arr);
      console.log("Records")
      console.log(records) 
      setPage(page+1)  
    }
    
   }
}

/*****On Loading Page********* */
useEffect(()=>{
    if(R>0) {R++;return;}       
    fetchMyAPI(10,"",1)
    fillRoles()
    requestEnterpriseList()
   
  },[])
/*******End of On Loading Page**** */

  /*********List Enterprise Roles*********** */
const [flagRefresh,setFlagRefres]=useState(false)
const [txtSearch,setTxtSearch]=useState('')
const handleRefresh = async() => {
  setTxtSearch('')
  setFilteredRows([])
  setFlagRefres(true)
  await fillRoles()
  await fetchMyAPI(10,"",1)
  }
const [roles, setRoles] = useState([]);
const addRoles = (newRecord) => {
  console.log("Add New Rocrods")  
  setRoles([...roles, newRecord]);
 
};  

const [serverResponse,setServerResponse]=useState([])
async function getRoles(tenant){
 
  const query=`
  query ListEnterpriseRoles {
    listEnterpriseRoles(tenant: \"${tenant}\") {
      created_at
      created_by
      deleted_at
      role_name
      tenant
      updated_at
      updated_by
    }
  }
  `
  const response=await getListItems(query)
  const roleArray=[];
  if(response!=null)
  response?.data?.listEnterpriseRoles.map((item)=>{
    roleArray.push(item.role_name)
  })

  return roleArray
}

/***********End Enterprise Roles***************************** */



//********Typeahead Search Section********* */



async function searchAPI(searchText) {
  setRequestFlag(true)
  const rows=[]
  const query = `
  query SearchEnterprise {
    searchEnterprises(limit: 10, searchStr: \"${searchText}\", nextToken: "",sortField: "enterprise_name", sortOrder: "asc") {
      data {
        enterprise_name
          description
          created_at
          created_by
          deactivated
          deleted_at
          tenant
          address_info
          website_url
          updated_at
          updated_by
      }
      nextToken
    }
  }
`;  

const graphqlResponse =  await getListItems(query)
console.log("Search Ahead => ")
console.log(graphqlResponse)
let urRows=graphqlResponse?.data?.searchEnterprises?.data;
for(let j=0;j<urRows.length;j++){
  let item=urRows[j]; 
  rows.push(
    createData( 
    j,
    item.enterprise_name,  
    item.tenant,
    item.description,       
    item.address_info,
    item.website_url,   
    item.created_at,
    item.updated_at
  ),
  )
}

  //setUiRows(rows)
  setFilteredRows(rows); 
  setRequestFlag(false)   
  //console.log("Data rows" + JSON.stringify(rows))
  console.log("Graph ql rESPONSE for search Enre=")
  console.log(filteredRows.length)
}

//********End of Typeahead Search Section********* */

  /***************End of the fetch data */
  const columns = [
    { field: 'enterprise_name', headerName: 'Enterprise Name',sortable: false,disableColumnMenu: true, minWidth: 80, flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header'},
    { field: 'created_at', headerName: 'Created',sortable: false,disableColumnMenu: true, minWidth: 80, flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',
    renderCell: (params) => {
      {new Date(params.value).toUTCString()}
    }

    },
    { field: 'updated_at', headerName: 'Updated',sortable: false,disableColumnMenu: true, minWidth: 80, flex: 1,flexWrap: 'wrap',headerClassName: 'super-app-theme--header',
    renderCell: (params) => {
      {new Date(params.value).toUTCString()}
    }
    },
    { field: 'action', headerName: 'Action', minWidth: 100,sortable: false,disableColumnMenu: true,headerClassName: 'super-app-theme--header', 
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation();
        console.log(params.notification_id)
        handleContextMenuOpen(e, params.row);
      };
  
      return (
        <>
         <Button onClick={onClick}><MoreVertIcon/></Button>
         
        </>
     
      );
    }
  
  },
  ];

  const [open, setOpen] = React.useState(false);
  const [delItem,setDelItem]=React.useState()

  async function checkRoles(tenant){
    const rows=[]
    const query = `
    query ListEnterpriseRoles {
      listEnterpriseRoles(tenant: \"${tenant}\") {
        created_at
        created_by
        deleted_at
        role_name
        tenant
        updated_at
        updated_by
      }
    }
        `;  

  const graphqlResponse =  await getListItems(query)
  console.log("Check Roles =")
  const roleCount=graphqlResponse?.data?.listEnterpriseRoles.length
  console.log(graphqlResponse?.data?.listEnterpriseRoles.length)
  return roleCount
  }
  
  const handleClickOpen = async (id,otype) => {
    setContextMenuAnchor(null);
    setDelItem(id)
    if(otype==='Create Role'){     
      navigate('/create-role',{state:{tenant:id,url:'all-account'}})
    }
    else if(otype==='Manage Connections'){    
  
      console.log(id)
      if(await checkRoles(id.tenant)>0){
        navigate('/list-enterpriseconnection',{state:{data:id,url:'all-account'}})
      }else{
        toast.info("No Roles Assigned")
      }
     
    } 
    else if(otype==='Manage Members'){    
      navigate('/list-enterpriseuser',{state:{tenant:id,url:'all-account'}})
    }
    else if(otype==='View Enterprise Details'){    
      navigate('/view-enterprisedetails',{state:{tenant:id,url:'all-account'}})
    }    
    else{
      setOpen(true);
    }   
  };

  const handleCloses = async (confirm) => {
    if(confirm=='yes'){    
      await deleteItems(delItem)     
    }else{
      setOpen(false)
    }  
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '250',
      },
    },
  };

  const [listRoles,setListRoles]=React.useState([])

  async function fillRoles() {
    setRoleName('')
    const rows=[]
    rows.push({label:'Filter By Role',value:'Filter By Role'})
    const query = `
    query MyQuery {
      listRoles
    }
  `; 

  const graphqlResponse =  await getListItems(query) 
  const rowsInfo=graphqlResponse.data.listRoles;
  rowsInfo.map((item)=>{
    rows.push({label:item,value:item})

    })
  setListRoles(rows)
  console.log("Rows Info=")
  console.log(listRoles) 

  }

  const [roleName,setRoleName]=useState('')
  const handlRoleChange=async(e)=>{
    setTxtSearch('')
  
    setRoleName(e.target.value) 
    if(e.target.value!='Filter By Role'){
      setRecords([])
      setFilteredRows([])
      await fetchByRole(e.target.value)     
    }else{
      await fetchMyAPI(10,"",1)  
    }
  }

const deleteItems=async(id)=>{
   //alert(id)
    const mutation = `
    mutation MyMutation {
      deleteEnterprise(tenant: \"${id}\", debugFunctions: "queryItemsPaginated,deleteItem") {
        ... on DeletedEnterprise {
          tenant
        }
        ... on CustomError {
          error
          message
          code
        }
      }
    }  
    `; 
    
console.log(mutation)    

const graphqlResponse =  await deleteItem(mutation)
console.log(graphqlResponse?.data?.deleteEnterprise)
const error=graphqlResponse?.data?.deleteEnterprise?.error
//alert(error)
if(error){
  toast.error(graphqlResponse?.data?.deleteEnterprise?.message);
  setOpen(false)
  return
}
setOpen(false)
let tmpRows=[]
for(let i=0;i<filteredRows.length;i++){
 if(filteredRows[i].tenant==id){
 }else{
   tmpRows.push(filteredRows[i])
 }
}
setFilteredRows(tmpRows);
navigate('/all-account')
toast.success('Records Deleted Successfully !');

}
  const contextMenu = (
    <Popover
      open={Boolean(contextMenuAnchor)}
      anchorEl={contextMenuAnchor}
      onClose={handleContextMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List> 
      <ListItem button>
        <ListItemText onClick={e=>handleClickOpen(selectedRow,'View Enterprise Details')} primary={`View Enterprise Details`} />
        </ListItem>
      <ListItem button>
        <ListItemText onClick={e=>handleClickOpen(selectedRow,'Manage Members')} primary={`Manage Members`} />
        </ListItem>
        <ListItem button>
        <ListItemText onClick={e=>handleClickOpen(selectedRow.tenant,'Create Role')} primary={`Manage Role`} />
        </ListItem>
        <ListItem button>
        <ListItemText onClick={e=>handleClickOpen(selectedRow,'Manage Connections')} primary={`Manage Connections`} />
        </ListItem>
        <ListItem button>
          <ListItemText onClick={e=>handleClickOpen(selectedRow.tenant,'Delete')} primary={`Delete`} />
        </ListItem>
      </List>
    </Popover>
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });



//miui pagination handling
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this to your desired items per page
  const data = filteredRows; // Replace with your data source
  const pageCount = Math.ceil(data.length / itemsPerPage);

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = async (event, page) => {
   
    if(records.length>=1){     
      if(page==1){
        /*for(let i=0;i<records.length;i++){
          await deleteRecord(records.length-i) 
        }*/             
       await fetchMyAPI(10,"",1)      
      }else{ 
        
        await fetchMyAPI(10,records[page-2].token,page)

      }      
      setCurrentPage(page);
    }
   
  };

  const handleSearch = async (event) => {  
    fillRoles() 
    setFilteredRows([])    
    await searchAPI(txtSearch)
  };


  const [pageSize, setPageSize] = React.useState(5);

  const handlePageSizeChange = async (newPageSize) => {
    //alert(newPageSize)
    setPageSize(newPageSize);
    
  };

  const [rowsPerPage, setrowsPerPage] = React.useState(5);
  const handleRowsPerPageChange = async (e) => {
    setIsClicked(true)   
    setrowsPerPage(e.target.value)
    await fetchMyAPI(e.target.value,"",1)
    setIsClicked(false)
    //alert(records.length)
    if((records.length*10)>=e.target.value){
      setlimits(true)
    }else{
      setlimits(false)
    }
   
  };
  
  

   /*********Prompt Notification*********** */
   const [requestRowsInfo,setRequestRowsInfo]=useState(null)
   const [enterpriseFlag,setEnterpriseFlag]=useState(false)
   const displayEnterpriseRequest=async(flag)=>{
     setEnterpriseFlag(flag)
   }
   const requestEnterpriseList=async ()=>{
     const query = `
     query ListRequestEnterprises {
       listRequestEnterprises(limit: 10, nextToken: "") {
         data {
           enterprise_id
           enterprise_name
           updated_at
           website_url
         }
         nextToken
       }
     }
   `; 
 
   const graphqlResponse =  await getListItems(query) 
   const rowsInfo=graphqlResponse.data.listRequestEnterprises;  
   setRequestRowsInfo(rowsInfo)
   console.log("Rows Info=")
   console.log(rowsInfo)  
   }

  return (
    <>
    <Helmet title="List Enterprise Details" />
  <Breadcrumbs aria-label="Breadcrumb" mt={2}>
    <Typography component="h1" variant="h5" align="center" gutterBottom>
    All Accounts

    </Typography> 
    </Breadcrumbs>
    <Divider my={6} />
    <Card mb={6}>
      <CardContent>      
      <BrowserView>      
      <Grid container spacing={12}>
        <Grid item >
        <div style={{marginBottom:'10px',textAlign:'center'}}> {(requestRowsInfo && requestRowsInfo.data && requestRowsInfo.data.length>=1 )?
      <Tooltip title="New enterprise registration requests found ">
        <Badge badgeContent={requestRowsInfo.data.length} color="error"> 
       <Stack direction="row" spacing={2}>
        <Link
              href='/view-enterpriserequest'          
              rel="noopener noreferrer"
            >
           <Chip
           variant="outlined"
            key={0}
            style={{color:'black',fontWeight:'bold'}}
            label={"New Requests Found"}
            color="error"           
           
          />
          </Link>
              
      </Stack>
     
          </Badge>
          </Tooltip>
           :<></>
           }
      </div> 
            
        </Grid>
       
       
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >           
            <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
              <Button mr={2} variant="contained" color="primary"  onClick={e=>navigate('/create-account')}>
                  + Add
              </Button>
            </div>            
        </Grid> 
        </Grid>
        </BrowserView> 
        <MobileView>
        <Grid container spacing={12}> 
        <Grid item >
        <div style={{marginBottom:'10px',textAlign:'center'}}> {(requestRowsInfo && requestRowsInfo.data && requestRowsInfo.data.length>=1 )?
      <Tooltip title="New enterprise registration requests found ">
        <Badge badgeContent={requestRowsInfo.data.length} color="success"> 
       <Stack direction="row" spacing={2}>
        <Link
              href='/view-enterpriserequest'          
              rel="noopener noreferrer"
            >
           <Chip
            key={0}
            label={"New Requests Found"}
            color="success"
           
          />
          </Link>
              
      </Stack>
     
          </Badge>
          </Tooltip>
           :<></>
           }
      </div> 
                     
        </Grid>
        
        <Grid item  style={{flex:1, textAlign:'end',paddingBottom:'6px'}} >           
          <div className='flex ' style={{textAlign:'end',paddingBottom:'6px'}}>
          <Button mr={2} variant="contained" color="primary" style={{padding:'10px'}} onClick={e=>navigate('/create-account')}>
              + Add
            </Button>
            </div>
            
        </Grid> 
        </Grid> 
           
        </MobileView>

      <div style={{paddingBottom:'10px',display: isMobile?'':'flex',gap:'10px' }}>
      {listRoles.length>0 &&     
      <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            style={{height:'36px',maxWidth:'350px'}}
            fullWidth
            onChange={handlRoleChange}
            value={roleName.length==0?listRoles[0].label:roleName}
            MenuProps={MenuProps}
          >
            {listRoles.map((item) => (
              <MenuItem key={item.label} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
            </Select>
      }
      
       <TextField       
        label="Search"
        variant="outlined"
        value={txtSearch}
        style={{marginTop:isMobile?'15px':''}}
        size="small"       
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e)=>setTxtSearch(e.target.value)}
      />
      <Button mr={2} variant="contained" color="primary" style={{height:'34px',maxWidth:'550px',marginTop:isMobile?'15px':''}}  onClick={handleSearch}>
       Search
      </Button>
       <IconButton color="primary" onClick={handleRefresh}>
        <RefreshIcon />
      </IconButton>
        </div>
      <div>
         <DataGrid
        paddingTop='10px'
        rows={filteredRows}
        columns={columns}
        fullWidth={true}
        autoHeight       
        sx={{
          height: 300,
          width: '100%',
          '& .super-app-theme--header': {
            backgroundColor: '#fff',
            fontSize:'15px',
            fontWeight:'bold'
            
          },
        }}
        onPaginationModelChange={setPaginationModel}        
        components={{         
          ColumnHeaderMenu: () => null, 
          ColumnsPanel: () => null, 
          Export: () => null,
          Pagination: ()=> {
            return (
              <div style={{display: 'flex',gap:'10px' }}>          
                  
              {/* Pagination component */}
              <div >
                  <label>Items per page:   </label>
                  <select style={{padding:'4px',margin:'6px'}} value={rowsPerPage} onChange={handleRowsPerPageChange}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>                   
                  </select>
                </div> 
              {limits==true &&
              <Stack direction="row" justifyContent="center">               
              <Pagination
                count={records.length+1}
                page={currentPage}
                onChange={handlePageChange}
                boundaryCount={10} // Adjust as needed
                showFirstButton
                showLastButton
                renderItem={(item) => (
                  <PaginationItem
                    component="div"
                    {...item}
                  />
                )}
              />
            </Stack>
              }  
              
            </div>  
            )
         },
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {requestFlag==true?<div style={{textAlign:'center'}}>
                    <img style={{width:'48px'}} src="/static/img/brands/loading.gif"/>          
          </div>:filteredRows.length==0 && requestFlag==false?'No Records Found':''

              }
              
              
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>
          )
         
        }}
        onCellClick={(params, event) => {
          
        }}
      />
      {contextMenu}
        
      </div>           
              
          <Paper mt={4}>          
            <Dialog
              open={open}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Operation?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <Alert mb={4}  severity="warning">
                Are you sure want to delete record ?
              </Alert>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e=>handleCloses("yes")} variant="contained" color="error">
                  Delete
                </Button>
                <Button onClick={e=>handleCloses("no")} color="success" variant="contained"  autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>

     
      </CardContent>
    </Card>
    
    </>
    
    
  );
}

export default DataTable;
