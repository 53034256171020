import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography,Avatar,Link,Button } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ResetPasswordComponent from "../../components/auth/ResetPassword";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 100%;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 64px;
  height: 64px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;


function ResetPassword() {
  return (
    <React.Fragment>
     
      <Wrapper>
        <Helmet title="Reset Password" />
      
        <Link href="/auth/sign-in"  ><ArrowBackIcon/></Link>
        <BigAvatar alt="Lucy" src="/static/img/brands/TCB_Blue_Bug.png" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
