import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Amplify} from 'aws-amplify';
import {  Auth } from '@aws-amplify/auth';
import { awsExports } from './aws-exports';
import OAuth from '@aws-amplify/auth/lib/OAuth/OAuth'
import { API, graphqlOperation } from 'aws-amplify';
// Import the CRUD functions
import { getAuthentication } from '../../utils/crud';

//import {SignIn,AuthProvider} from  '../../contexts/CognitoContext'

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { LogOut } from "react-feather";
import routes from "../../routes";
import { ja } from "date-fns/locale";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);


function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const flagemail = false;

  const _move=(_resp)=>{
    navigate("/auth/setup-password",{state:{resp:_resp}});           
 
  }
  return (
    <Formik 
      initialValues={{
        email: localStorage.getItem("email"),
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {          
          const jsonData={"email":values.email,"password":values.password}
          const resp=await getAuthentication(jsonData)
          console.log(resp)
         
          if(resp.challengeName=='NEW_PASSWORD_REQUIRED'){                     
            navigate("/auth/setup-password")
          }else {
            if(resp.attributes.email_verified){
              
              navigate("/all-account");
            }
          }     
        
         
        } catch (error) {
          console.log(error)
          

          if(JSON.parse(JSON.stringify(error)).name==='NotAuthorizedException' || JSON.parse(JSON.stringify(error)).name==='UserNotFoundException'){
            const message = error.password || "Incorrect user or password";
            //const notify = () => toast('Incorrect user or password');
            //notify()
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
    

          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate  onSubmit={handleSubmit}>
          {/*<Alert mt={3} mb={3} severity="success">
            {localStorage.getItem("email")}
      </Alert>*/}
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
         
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
        

          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign in
          </Button>
          {<Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>}
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
